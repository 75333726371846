import React from "react";
//import React, {useContext} from "react";
//import {Context} from './../../context/ContextProvider';
import { useEffect, useState} from "react";
import './../Page.css';
import {useNavigate ,NavLink } from "react-router-dom";
import {sendMyGame , sendMyGamePerso } from "./../../actions/user.js";
import Arrow from './../../items/Image/arrow3.svg';
import Controller from "./../../items/Image/controller.svg";
import $ from "jquery";




export default function AddGame() {
  //const {connected, updateconnected} = useContext(Context);
  const navigate = useNavigate();
  const [suratInfo, setSuratInfo] = useState({});

  const getSuratInfoApi=()=>{
    //const data = { UserId: 1,Game:id};


    fetch('https://hafiz-quran.com/express/suratinfo', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
    .then((response) => response.json())
    .then((data) => {
      setSuratInfo(data);
    },

      (error) => {
        console.log("Pas de Connexion");
      }

    );
  }
    useEffect(() => {

        getSuratInfoApi();

        return () => {
        }
    }, []);

    let datal=""
    if(suratInfo[1]){
        for(let i=0; i<Object.keys(suratInfo).length; i++){
            datal=datal+"<option value="+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" >"+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Francais"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Arabe"]+"</option>";
        }
    }
    const changeInput1=()=>{
    }
    const changeChoice=()=>{
        if($("#choice").val()==="All"){
            $("#txtinput").addClass("exit");
            $("#juz").addClass("exit");
            $("#hizb").addClass("exit");
            $("#rub").addClass("exit");
            $("#surat").addClass("exit");
            $("#juztxtinput").addClass("exit");
            $("#hizbtxtinput").addClass("exit");
            $("#rubtxtinput").addClass("exit");
            $("#surattxtinput").addClass("exit");

        }
        else if($("#choice").val()==="Juz"){
            $("#txtinput").removeClass("exit");
            $("#juz").removeClass("exit");
            $("#hizb").addClass("exit");
            $("#rub").addClass("exit");
            $("#surat").addClass("exit");
            $("#juztxtinput").removeClass("exit");
            $("#hizbtxtinput").addClass("exit");
            $("#rubtxtinput").addClass("exit");
            $("#surattxtinput").addClass("exit");

        }
        else if($("#choice").val()==="Hizb"){
            $("#txtinput").removeClass("exit");
            $("#juz").addClass("exit");
            $("#hizb").removeClass("exit");
            $("#rub").addClass("exit");
            $("#surat").addClass("exit");
            $("#juztxtinput").addClass("exit");
            $("#hizbtxtinput").removeClass("exit");
            $("#rubtxtinput").addClass("exit");
            $("#surattxtinput").addClass("exit");

        }
        else if($("#choice").val()==="Rub"){
            $("#txtinput").removeClass("exit");
            $("#juz").addClass("exit");
            $("#hizb").addClass("exit");
            $("#rub").removeClass("exit");
            $("#surat").addClass("exit");
            $("#juztxtinput").addClass("exit");
            $("#hizbtxtinput").addClass("exit");
            $("#rubtxtinput").removeClass("exit");
            $("#surattxtinput").addClass("exit");

        }
        else{
            $("#txtinput").addClass("exit");
            $("#juz").addClass("exit");
            $("#hizb").addClass("exit");
            $("#rub").addClass("exit");
            $("#surat").removeClass("exit");
            $("#juztxtinput").addClass("exit");
            $("#hizbtxtinput").addClass("exit");
            $("#rubtxtinput").addClass("exit");
            $("#surattxtinput").removeClass("exit");

        }
        
    }
    
    const changeChoice2=()=>{
        if($('#myCheckbox').is(':checked')){
            $("#txtinput2").removeClass("exit");
            $("#txtinput3").removeClass("exit");
            $("#timer").removeClass("exit");
        }
        else{
            $("#txtinput2").addClass("exit");
            $("#txtinput3").addClass("exit");
            $("#timer").addClass("exit");
        }
    }

    
    const goPlay = (IDGame) => {
        if(IDGame){
            navigate("/game/play/"+Math.floor(IDGame), { connection: true });
        }
        
    };
    /*
    const goPlayPerso = (Version,IDGame) => {
        if(Version && IDGame && Version==="Classique"){
            navigate("/game/play/"+IDGame, { connection: true });
        }
        else if(Version && IDGame && Version==="SuratO"){
            navigate("/game/play-surat/"+IDGame, { connection: true });
        }
    };
    */
    const SendGameInfo=()=>{
        let Version="Classique";
        if($("#choice2").val()==="SuratO"){
            Version="SuratO"
        }
        else if ($("#choice2").val()==="Perso"){
            if($("input[name='gender']:checked").val()){
                Version = $("input[name='gender']:checked").val();
            }
        }
        if($("#choice").val()==="All"){
            if ($('#myCheckbox').is(':checked')){
                sendMyGamePerso(Version,"All",1,[],Math.floor($("#timer").val()),goPlay);
            }
            else{
                sendMyGame(Version,"All",1,[],goPlay);
            }

        }
        else if($("#choice").val()==="Juz"){
            if(!isNaN($("#juz").val()) && Math.floor($("#juz").val())>0 && Math.floor($("#juz").val())<31){
                if ($('#myCheckbox').is(':checked')){
                    sendMyGamePerso(Version,"Juz",Math.floor($("#juz").val()),[],Math.floor($("#timer").val()),goPlay);

                }
                else{
                    sendMyGame(Version,"Juz",Math.floor($("#juz").val()),[],goPlay);
                }
                
            }
            else{
                alert("La valeur du numéro de juz n'est pas correct");
            }

        }
        else if($("#choice").val()==="Hizb"){
            if(!isNaN($("#hizb").val()) && Math.floor($("#hizb").val())>0 && Math.floor($("#hizb").val())<61){
                if ($('#myCheckbox').is(':checked')){
                    sendMyGamePerso(Version,"Hizb",Math.floor($("#hizb").val()),[],Math.floor($("#timer").val()),goPlay);

                }
                else{
                    sendMyGame(Version,"Hizb",Math.floor($("#hizb").val()),[],goPlay);
                }

            }
            else{
                alert("La valeur du numéro de hizb n'est pas correct");
            }
        }
        else if($("#choice").val()==="Rub"){
            if(!isNaN($("#rub").val()) && Math.floor($("#rub").val())>0 && Math.floor($("#rub").val())<241){
                if ($('#myCheckbox').is(':checked')){
                    sendMyGamePerso(Version,"Rub",Math.floor($("#rub").val()),[],Math.floor($("#timer").val()),goPlay);
                }
                else{
                    sendMyGame(Version,"Rub",Math.floor($("#rub").val()),[],goPlay);
                }
            }
            else{
                alert("La valeur du numéro de rub n'est pas correct");
            }
        }
        else{
            if(!isNaN($("#surat").val()) && Math.floor($("#surat").val())>0 && Math.floor($("#surat").val())<115){
                if ($('#myCheckbox').is(':checked')){
                    sendMyGamePerso(Version,"Surat",Math.floor($("#surat").val()),[],Math.floor($("#timer").val()),goPlay);
                }
                else{
                    sendMyGame(Version,"Surat",Math.floor($("#surat").val()),[],goPlay);
                }
            }
            else{
                alert("La valeur du numéro de sourate n'est pas correct");
            }
        }
    }


  return (
    <>
    <div className=" firstaddgame centerco apparitionq">

        <p className="centerco">
            <label htmlFor="choice">Sur quel partie du Coran voulez vous pratiquez ?</label><br/>
            <select name="choice" className="inp4 inputdefault pointer inpaddgame" id="choice" onInput={changeChoice}>
                <option value="All">Tout le Coran</option>
                <option value="Juz">Sur un Juz</option>
                <option value="Hizb">Sur un Hizb</option>
                <option value="Rub">Sur un Rub</option>
                <option value="Surat">Sur une Sourate</option>
            </select>
        </p>
        
        <p id="txtinput" className="apparitionq exit">Sur lequel d'entre eux ? maximum <span id="juztxtinput" className="exit">30</span><span id="hizbtxtinput" className="exit">60</span><span id="rubtxtinput" className="exit">240</span></p>
        <input type="number" name="juz" id="juz" className="inp3 inputdefault apparitionq pointer exit inpaddgame" min={1} max={30} placeholder="Juz" required=""></input>
        <input type="number" name="hizb" id="hizb" className="inp3 inputdefault apparitionq pointer exit inpaddgame" min={1} max={60} placeholder="Hizb" required=""></input>
        <input type="number" name="rub" id="rub" className="inp3 inputdefault apparitionq pointer exit inpaddgame" min={1} max={240} placeholder="Rub" required=""></input>
        <p id="surattxtinput" className="apparitionq exit">Sur laquel d'entre elle ? maximum 114</p>
        <input id="surat" className="inp1 inputdefault apparitionq pointer talign exit surantinpaddgame" name="surat" placeholder="Surat" list="suratname" onInput={changeInput1}></input>
            <datalist id="suratname">

                <div dangerouslySetInnerHTML={{ __html: datal }} />

            </datalist>

        <p className="centerco">
            <label htmlFor="choice2">Sur quel version voulez vous pratiquez ?</label><br/>
            <select name="choice2" className="inp4 inputdefault pointer inpaddgame" id="choice2">
                <option value="Classique">Classique</option>
                <option value="SuratO">Surat Only</option>
            </select>
        </p>

        <label className="custom-checkbox">
            <input type="checkbox" id="myCheckbox" className="hidden-checkbox" value="Perso" onInput={changeChoice2} />
            <span className="checkmark"></span>
            Personnalisé
        </label>
        <p id="txtinput2" className="apparitionq exit">{"( Les parties personnalisé ne sont pas classés )"}</p>
        <label id="txtinput3" className="apparitionq exit">Timer personnalisé</label>
        <input type="number" name="timer" id="timer" className="inp3 inputdefault apparitionq pointer exit inpaddgame" min={1} max={999999} placeholder="Timer" required="" defaultValue={60}></input>

        <div style={{height:"2em"}}></div>
        <button className="returngame pointer center" onClick={SendGameInfo} ><img alt='' className="imglink " src={Controller}></img>Créer une partie</button>

    </div>
    <div className="centerco"> 
                <NavLink className={"linkclass"} to="/game/">
                    <button className="returngame pointer center" ><img alt='' className="imglink arrowreturn" src={Arrow}></img>Retour au menu</button>
                </NavLink>
                <NavLink className={"linkclass"} to="/game/list-game">
                    <button className="returngame pointer center" >Reprendre une partie<img alt='' className="imglink arrowreturn2" src={Arrow}></img></button>
                </NavLink>
    </div>
    </>
  );
}
//import React, {createContext, useState} from 'react';
import React, { useState} from 'react';


export const ContextProvider = ({ children }) => {

  const [connected, setconnected] = useState(false);

  const updateconnected = (newValue) => {
    setconnected(newValue);
  };


  const [currentUser, setcurrentUser] = useState(null);

  const updatecurrentUser = (newValue) => {
    setcurrentUser(newValue);
  };



  return (
    <Context.Provider value={{ currentUser, updatecurrentUser, connected , updateconnected }}>
      {children}
    </Context.Provider>
  );
};

// Créer un contexte en utilisant React.createContext et fournir les états et fonctions
export const Context = React.createContext({
  currentUser:JSON.parse(localStorage.getItem('currentUser')),
  connected:false,
}
);

export default ContextProvider;

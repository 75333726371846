import React, { useState, useEffect } from 'react';
import './TimerCircle.css';  // N'oubliez pas d'ajouter le CSS

const TimerCircle = ({ seconds, onComplete }) => {
    const [currentSeconds, setCurrentSeconds] = useState(seconds);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentSeconds((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            if (onComplete) onComplete();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }, [onComplete]);
  
    return (
        <div>
            <div className="timerscreen centrer">
                <div className="chrono centrerco">
                    <div className="firstcircle centrer">
                        <div className="barre2 centrer App-logo"></div>
                    </div>
                    <div className="circle3 centrer">
                        <h4 className="number white">{currentSeconds}</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimerCircle;


import React, {useContext} from "react";
import { useEffect} from "react";
import { Link } from "react-router-dom";
import {Context} from '../../context/ContextProvider';
import './Footer.css';
import { ReactComponent as LogoFoot } from './../../items/Image/Bookplus2.svg';
import { ReactComponent as WhiteFacebook } from './../../items/Image/icon/whitefacebook.svg';
import { ReactComponent as WhiteTwitter } from './../../items/Image/icon/whitetwitter.svg';
import { ReactComponent as WhiteInsta } from './../../items/Image/icon/whiteinsta.svg';
import { ReactComponent as WhiteSend } from './../../items/Image/icon/whitesend.svg';
import { ReactComponent as WhiteYoutube } from './../../items/Image/icon/whiteyoutube.svg';




import $ from "jquery";




export default function Header() {
  //const {connected, updateconnected} = useContext(Context);
  const {connected} = useContext(Context);


  useEffect(
    () => {

      $( ".logofoot" ).mouseover(function() {
        $(".cls-6book").attr("class","cls-6book logopathenter2")
      });
      $( ".logofoot" ).mouseleave(function() {
        $(".cls-6book").attr("class","cls-6book")
      });
      $( ".logoface" ).mouseover(function() {
        $(".cls-face").attr("class","cls-face logopathenter")
      });
      $( ".logoface" ).mouseleave(function() {
        $(".cls-face").attr("class","cls-face")
      });
      $( ".logotwitt" ).mouseover(function() {
        $(".cls-twitt").attr("class","cls-twitt logopathenter")
      });
      $( ".logotwitt" ).mouseleave(function() {
        $(".cls-twitt").attr("class","cls-twitt")
      });
      $( ".logoinsta" ).mouseover(function() {
        $(".cls-insta1").attr("class","cls-insta1 hamenter")
        $(".cls-insta2").attr("class","cls-insta2 hamenter")

      });
      $( ".logoinsta" ).mouseleave(function() {
        $(".cls-insta1").attr("class","cls-insta1")
        $(".cls-insta2").attr("class","cls-insta2")

      });
      $( ".logosend" ).mouseover(function() {
        $(".cls-send1").attr("class","cls-send1 hamenter")
        $(".cls-send2").attr("class","cls-send2 hamenter")

      });
      $( ".logosend" ).mouseleave(function() {
        $(".cls-send1").attr("class","cls-send1")
        $(".cls-send2").attr("class","cls-send2")

      });
      $( ".logoyou" ).mouseover(function() {
        $(".cls-you").attr("class","cls-you logopathenter")
      });
      $( ".logoyou" ).mouseleave(function() {
        $(".cls-you").attr("class","cls-you")
      });


    },
    [],
  );

  return (
    <>
      <footer id="footer" className="footer apparition">
        <div className="divfoot">
          <div className="boxlogofoot"><Link className="" to="/"><LogoFoot className="logofoot pointer"></LogoFoot></Link></div>
          <div className="divnav">
            <h4 className="titlenav">Pages</h4>
            <hr className="hr2foot"></hr>
            <nav className="navfoot">
              <Link className="linkfoot pointer" to="/">Accueil</Link>
              <Link className="linkfoot pointer" to="/a-propos">À Propos</Link>
              <Link className="linkfoot pointer" to="/lecture">Lecture</Link>
              <Link className="linkfoot pointer" to="/contact">Contact</Link>
              {connected&&(<Link className="linkfoot pointer account2 exit" to="/mon-compte">Mon Compte</Link>)}
            </nav>
          </div>
          <div className="divnav">
            <h4 className="titlenav">À lire</h4>
            <hr className="hr2foot"></hr>
            <nav className="navfoot">
              <Link className="linkfoot pointer" to="/condition-generales-utilisations">Conditions générales d'utilisation</Link>
              <Link className="linkfoot pointer" to="/mentions-legales">Mentions Légales</Link>
            </nav>
          </div>
        </div>
        <div className="div2foot">
              <hr className="barrehr"></hr>
              <div className="entrebarre"><Link target="_blank" rel="noopener noreferrer" className="" to="https://www.facebook.com/ummahtechnologie"><WhiteFacebook className="logoface pointer"></WhiteFacebook></Link> <Link target="_blank" rel="noopener noreferrer" className="" to="https://twitter.com/astro_num"><WhiteTwitter className="logotwitt pointer"></WhiteTwitter></Link> <Link target="_blank" rel="noopener noreferrer" className="" to="https://www.instagram.com/ummahtechnologie/"><WhiteInsta className="logoinsta pointer"></WhiteInsta></Link> <Link target="_blank" rel="noopener noreferrer" className="" to="mailto:astronum94@gmail.com"><WhiteSend className="logosend pointer"></WhiteSend></Link><Link target="_blank" rel="noopener noreferrer" className="exit" to="https://www.youtube.com"><WhiteYoutube className="logoyou pointer"></WhiteYoutube></Link> </div>
              <hr className="barrehr"></hr>
        </div>
        <div className="div3foot">
              <h4 className="copywright"><a target="_blank" rel="noopener noreferrer" href="https://ummah-tech.com/"><span style={{color:"white"}}>2023@</span><span style={{color:"rgb(44, 139, 221)"}}>ummah-tech</span></a></h4>
              
        </div>


      </footer>

    </>
  );
}
import React, { useState , useEffect} from 'react';
import './ToggleSwitch.css';

function ToggleSwitch(props) {
    const [isActive, setIsActive] = useState(false);
    const {first}=props;

    const ChangeToggle=()=>{
        if (props.fonct){
            props.fonct(!isActive);
        }
    }
    
useEffect(
    () => {

        if(first){
            setIsActive(first)
        }


      return () => {
      }

    },
    [first],
);

    return (
        <div className="toggleSwitch-container">
            <label className="toggleSwitch" onClick={ChangeToggle}>
                <input 
                    type="checkbox" 
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                />
                <span className="toggleSwitch-round"></span>
            </label>
        </div>
    );
}

export default ToggleSwitch;
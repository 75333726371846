import { configureStore } from "@reduxjs/toolkit";
//import counterReducer from "./features/counter/counterSlice";
import onHeaderSlice from "./../features/onHeader.js";
import onFooterSlice from "./../features/onFooter.js";

export const store= configureStore({
    reducer: {
        //counter: counterReducer,
        //onHeader: onHeaderSlice.reducer,
        onHeader: onHeaderSlice,
        onFooter: onFooterSlice,
    },
});
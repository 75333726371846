import React, { useEffect, useRef } from 'react';
import './Audio.css';
import imgplay from "./Img/play.svg";
import imgpause from "./Img/pause.svg";
import imgstop from "./Img/stop.svg";
import imgspeaker from "./Img/speaker.svg";

export default function Audio(props) {
    //const audiopRef = useRef(null);
    const playbuttonRef = useRef(null);
    const audiostopRef = useRef(null);
    const audiovolumeRef = useRef(null);
    const seekBarRef = useRef(null);
    const fillRef = useRef(null);
    const audiovolumeslideRef = useRef(null);
    const imgFirstRef = useRef(null);
    const audiosectionRef = useRef(null);
    //const [Listen, setListen] = useState(false);
    const { Listen } = props;
    const { setListen } = props;
    const { EndBool } = props;
    const { audio1 } = props;
    const { dontStop } = props;
    const { fonctionEnd } = props;
    const { audiopRef } = props;
    const { Begin } = props;
    const { taille } = props;






    const changeListen = () => {
        // Votre logique de fonction...
        setListen(!Listen);
        /*if (imgFirstRef.current) {
            imgFirstRef.current.classList.remove("disparition");
            imgFirstRef.current.classList.add("disparition");

            setTimeout(() => {
                setListen(!Listen);
                imgFirstRef.current.classList.remove("disparition");
            }, 150);
        }*/

        const audio = audiopRef.current;

        if (audio.classList.contains("play")) {
            audio.classList.remove("play");

            audio.pause();
        } else {
            audio.classList.add("play");
            audio.play();
        }

    }
    const StopAudio = () => {
        const audio = audiopRef.current;

        audio.load();
        audio.classList.remove("play");
        setListen(false);
    }

    const clickVolume = (e) => {

        if (e.target.classList.contains("audiovolumeslide")) {
            return;
        }
        if(audiovolumeRef.current.classList.contains("opened")){
            if(audiosectionRef.current.classList.contains("audioinplay")){
                const element = audiovolumeRef.current;

                // Pour animer
                element.style.transition = 'height 0.5s';
                element.style.height = '1.6em';
        
                // Pour ajouter/retirer une classe
        
                // Écoutez l'événement transitionend et retirez la propriété de transition une fois l'animation terminée
                const handleTransitionEnd = () => {
                    element.style.transition = '';
                    element.classList.remove('opened');
                    element.removeEventListener('transitionend', handleTransitionEnd);
                };
        
                element.addEventListener('transitionend', handleTransitionEnd);
            }
            else{

                const element = audiovolumeRef.current;

                // Pour animer
                element.style.transition = 'height 0.5s';
                element.style.height = '2.1em';
        
                // Pour ajouter/retirer une classe
        
                // Écoutez l'événement transitionend et retirez la propriété de transition une fois l'animation terminée
                const handleTransitionEnd = () => {
                    element.style.transition = '';
                    element.classList.remove('opened');
                    element.removeEventListener('transitionend', handleTransitionEnd);
                };
        
                element.addEventListener('transitionend', handleTransitionEnd);

            }
        }
        else{

            const element = audiovolumeRef.current;

            // Pour animer
            element.style.transition = 'height 0.5s';
            element.style.height = '7em';
    
            // Pour ajouter/retirer une classe
    
            // Écoutez l'événement transitionend et retirez la propriété de transition une fois l'animation terminée
            const handleTransitionEnd = () => {
                element.style.transition = '';
                element.classList.add('opened');
                element.removeEventListener('transitionend', handleTransitionEnd);
            };
    
            element.addEventListener('transitionend', handleTransitionEnd);

        }

    }

    const ChangeVolume = () => {


            if (audiovolumeslideRef.current && audiopRef.current) {
                let vv = audiovolumeslideRef.current.value;
                // Utiliser audiopRef pour accéder à l'élément audio
                let audio = audiopRef.current;
                audio.volume = vv / 100; 
            }

    }

    function offset(el) {
        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    const handleSeekBarClick = (event) => {
        const divOffset = offset(seekBarRef.current);
        if(taille){
            audiopRef.current.currentTime = (Math.trunc(event.clientX - divOffset.left)) * audiopRef.current.duration / (Math.floor(taille)*16);
        }
        else{
            audiopRef.current.currentTime = (Math.trunc(event.clientX - divOffset.left)) * audiopRef.current.duration / 224 ;
        }
    };


    useEffect(() => {
        const x = audiopRef.current;

        const intervalID=setInterval(()=>{
            //$("#slideraudio").value=50;
            var xp=x.currentTime;
            var xp0=x.duration;
            var xpopo;
            if(taille){
                xpopo=xp*(Math.floor(taille)/xp0);
            }
            else{
                xpopo=xp*(14/xp0);
            }
            const element0 = fillRef.current;
            element0.style.width = xpopo+"em";
            //$("#fill").css( "width", xpopo+"em");
        },100)


        return () => {
            clearInterval(intervalID);

        }
    }, [taille,audiopRef]);

    useEffect(() => {
        if(Math.floor(Begin)){
            audiopRef.current.currentTime=Begin;
        }
        return () => {
        }
    }, [Begin,audiopRef]);



    useEffect(() => {
        const x = audiopRef.current;

        const handleAudioEnd = () => {
            console.log('Audio has ended.');
            if(!EndBool){
                if (audiostopRef.current) {
                    audiostopRef.current.click();
                } else {
                    console.log("Error");
                }
            }
            else{
                fonctionEnd();
            }

            // Effectuez d'autres actions ici si nécessaire
        };
            x.addEventListener('ended', handleAudioEnd);



        return () => {
            if (x) {
                x.removeEventListener('ended', handleAudioEnd);
            }
        }
    }, [EndBool,fonctionEnd,audiopRef]);


    useEffect(() => {
        const x = audiopRef.current;
        x.load();
        if(dontStop && x.classList.contains("play")){
            x.play();
        }
        else{
            if (audiostopRef.current) {
                audiostopRef.current.click();
            } else {
                console.log("Error");
            }
        }
        
    }, [audio1,dontStop,audiopRef]);

    return (
        <>
            <div id="audiosection" ref={audiosectionRef} className={props.classp} style={{ width: "16.8em" }}>
                {!props.noTitle &&(<h2>Audio :</h2>)}
                <div className='center allbuttons'>
                    <button ref={playbuttonRef} onClick={changeListen} className='btn1'>
                        {Listen ? 
                            <img ref={imgFirstRef}  className='imgfirst imgbutton apparition' alt='pause bouton' src={imgpause} /> : 
                            <img ref={imgFirstRef}  className='imgfirst imgbutton apparition' alt='play bouton' src={imgplay} />}
                    </button>
                    <button ref={audiostopRef} className='btn1' onClick={StopAudio} >
                        <img alt='stop bouton' className='imgbutton transitiona' src={imgstop} />
                    </button>
                    <div className="testvolumecont rot180">
                        <div ref={audiovolumeRef} onClick={clickVolume} className='audiovolume pointer'>
                            <svg className='exit' style={{ transform: "translateY(0.8em)scale(1.7)rotate(180deg)", maxWidth: "0.6em", minWidth: "0.6em" }} xmlns="http://www.w3.org/2000/svg" width="20.5" height="20.375" viewBox="0 0 20.5 20.375">
                                {/* SVG Content */}
                                <path id="speaker" d="M0,6.034H4.831L12.1.111V20.486L4.831,14.562H0Zm14.829-.966a7.239,7.239,0,0,1,2.184,5.209,6.746,6.746,0,0,1-2.184,5.041l-1.47-1.512a4.883,4.883,0,0,0,1.512-3.571,5.07,5.07,0,0,0-1.512-3.655l1.47-1.512Zm2.52-2.478a10.361,10.361,0,0,1,3.151,7.6,10.5,10.5,0,0,1-3.151,7.645l-1.555-1.554a8.185,8.185,0,0,0,2.52-6.07A8.323,8.323,0,0,0,15.795,4.1l1.555-1.512Z" transform="translate(0 -0.111)" fill="#FEFCFD"/>
                            </svg>
                            <img alt='speaker bouton' className='imgbutton transitiona rot180 imgspeaker' src={imgspeaker} />
                            <div className='rot180'>
                                <input ref={audiovolumeslideRef} onChange={ChangeVolume} type="range" className="slidersa3 pointer audiovolumeslide" min="0" max="100" defaultValue="50" style={{ transform: "rotate(270deg)", position: "relative", top: "-3.6em" }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="messaudio" style={{ display: "none" }}>Il n'y a pas d'audio disponible</div>
                {taille&&(
                    <div style={{ width:Math.floor(taille).toString()+"em",transform:"translateX("+((14-Math.floor(taille))/2)+"em)"}} ref={seekBarRef} onClick={handleSeekBarClick} className='seek-bar0'>
                        <div className="fill" ref={fillRef}></div>
                        <div className="handle"></div>
                    </div>
                )}
                {!taille&&(
                    <div style={{ /*styles*/ }} ref={seekBarRef} onClick={handleSeekBarClick} className='seek-bar'>
                        <div className="fill" ref={fillRef}></div>
                        <div className="handle"></div>
                    </div>
                )}
                <audio ref={audiopRef} style={{ zIndex: "10", transform: "translateY(-87px)" }}  >
                    <source src={props.audio1} type="audio/mpeg"></source>
                    Your browser does not support the audio element.
                    <button></button>
                </audio>
            </div>
        </>
    );
}
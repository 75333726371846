import React, {useContext , useEffect, useState,useCallback} from "react";
import {Context} from '../../context/ContextProvider';
import './../Page.css';
import { useNavigate ,NavLink } from "react-router-dom";
import Arrow from './../../items/Image/arrow3.svg';
import ToggleSwitch from "./../../Components/ToggleSwitch/ToggleSwitch";

import { checkcon , ChangeAudioGame , ChangeArabicGame , ChangeEnglishGame , ChangeFrenchGame , getReglage } from "../../actions/user.js";



export default function Reglage() {
  //const {connected, updateconnected} = useContext(Context);
  const {connected} = useContext(Context);
  const navigate = useNavigate();
  const [Audio, setAudio] = useState(false);
  const [French, setFrench] = useState(false);
  const [English, setEnglish] = useState(false);
  const [Arabic, setArabic] = useState(false);
  const [Begin, setBegin] = useState(false);



const goConnexion = useCallback((bool) => {
    if (!bool) {
        navigate("/connexion", { connection: true });
    }
}, [navigate]); 


useEffect(
  () => {

    getReglage((result)=>{
      setAudio(result.Audio);
      setArabic(result.Arabic);
      setFrench(result.French);
      setEnglish(result.English);
      setBegin(true);

    })

    return () => {
    }

  },
  [connected],
);

  useEffect(
    () => {

        if(!connected){
            checkcon(goConnexion);
            //navigate("/connexion", { connection: true });
        }
        else if(connected){
        }

      return () => {
      }

    },
    [connected,goConnexion],
);

useEffect(
  () => {

    

    return () => {
    }

  },
  [],
);


  return (
    <>
    {Begin && (
      <div className="centerco apparitionq">
        <div style={{height:"1em"}}></div>
        <div className="center">Audio : <ToggleSwitch first={Audio} fonct={(result)=>{ChangeAudioGame(result)}}></ToggleSwitch></div>
        <div className="center">Arabic : <ToggleSwitch first={Arabic} fonct={(result)=>{ChangeArabicGame(result)}}></ToggleSwitch></div>
        <div className="center">French : <ToggleSwitch first={French} fonct={(result)=>{ChangeFrenchGame(result)}}></ToggleSwitch></div>
        <div className="center">English : <ToggleSwitch first={English} fonct={(result)=>{ChangeEnglishGame(result)}}></ToggleSwitch></div>
        <div style={{height:"1em"}}></div>
      </div>
    )}

    <div className="centerco"> 
                <NavLink className={"linkclass"} to="/game/">
                    <button className="returngame pointer center" ><img alt='' className="imglink arrowreturn" src={Arrow}></img>Retour au menu</button>
                </NavLink>
                <NavLink className={"linkclass"} to="/game/list-game">
                    <button className="returngame pointer center" >Reprendre une partie<img alt='' className="imglink arrowreturn2" src={Arrow}></img></button>
                </NavLink>
    </div>
    </>
  );
}
import React, { useState, useEffect } from 'react';
import partenairesLogos from './partenaires.json';
import './Partenaire.css';
import Arrow from './../../items/Image/arrow3.svg';

function Partenaire() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(4);

  useEffect(() => {
    const updateItemsToShow = () => {
      if (window.innerWidth < 480) {
        setItemsToShow(1);
      } else if (window.innerWidth < 768) {
        setItemsToShow(2);
      } else if (window.innerWidth < 1024) {
        setItemsToShow(3);
      } else {
        setItemsToShow(4);
      }
    };

    window.addEventListener('resize', updateItemsToShow);
    updateItemsToShow();

    return () => window.removeEventListener('resize', updateItemsToShow);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + itemsToShow) % partenairesLogos.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [itemsToShow]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - itemsToShow + partenairesLogos.length) % partenairesLogos.length);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + itemsToShow) % partenairesLogos.length);
  };

  return (
    <div className="presentation-section partenaires-section centerco">
      <h2>Partenaires</h2>
      <div className="logos-wrapper">
        <p><strong>Merci à nos Partenaires</strong></p>
        <p>Un grand merci à nos partenaires pour leur soutien et leur confiance. Grâce à vous, ce projet est devenu réalité.</p>
        {partenairesLogos.slice(currentIndex, currentIndex + itemsToShow).map((logo, index) => (
          <a  target="_blank" rel="noopener noreferrer" key={index} href={logo[1] }><img  className='bigger pointer imgpartenaire'  src={logo[0]} alt={`Logo Partenaire ${index}`} /></a>
        ))}
        <div className='center'>
          <button className="returngame pointer center" onClick={prevSlide}><img className="smallarrow" src={Arrow} alt="Previous"></img></button>
          <button className="returngame pointer center" onClick={nextSlide}><img className="smallarrow2" src={Arrow} alt="Next"></img></button>
        </div>
      </div>
    </div>
  );
}

export default Partenaire;
import React from "react";

import './Page.css';
//import old from './../items/Image/illustrations/old.webp';
import old from './../items/Image/illustrations/muslim_computer_no_hand-_2_.webp';
//import room from './../items/Image/illustrations/room.webp';
import room from './../items/Image/illustrations/reader2.webp';
//import machine from './../items/Image/illustrations/machine.webp';
//import machine from './../items/Image/illustrations/machine2.webp';
//import machine from './../items/Image/illustrations/machine3.webp';
import machine from './../items/Image/illustrations/logoastronum.svg';
import Partenaires from "../Components/Partenaire/Partenaire";
import Help from "../Components/Help";



export default function Propos() {

  return (
    <>
      <section id="propos" className="bigbox apparition">
        <h1 className="titlepage titleaccueil">À Propos</h1>
        <div className="boxparentpropos a768">
          <div className="parentprops ligne1">
            <div className="div1props  tobottomroom">
              <div className="presentation-section notop">

                  <section className="introduction">
                      <h2>Introduction</h2>
                      <p>HAFIDH est un jeu éducatif conçu pour encourager l'interaction avec le Coran. Notre objectif est d'inspirer les joueurs à lire le Coran plus souvent et à l'apprendre régulièrement à travers une expérience ludique et stimulante.</p>
                  </section>

                  <section className="how-it-works">
                      <h2>Comment ça marche ?</h2>
                      <ul>
                          <li><strong>Trouver la sourate et le verset mystère</strong> : Identifiez la sourate et le verset, le tout sous la pression d'un compte à rebours.</li>
                          <li><strong>Identifier la sourate</strong> : Si vous connaissez bien vos sourates, testez votre mémoire en essayant de reconnaître la sourate à partir d'un verset spécifique.</li>
                          <li><strong>Mode personnalisé</strong> : Créez des parties personnalisées avec un temps choisi. Bien que ces parties ne soient pas classées, elles offrent une expérience sur mesure pour approfondir votre relation avec le Coran.</li>
                      </ul>
                  </section>

                  <section className="rankings">
                      <h2>Classements</h2>
                      <p>Mesurez-vous aux autres joueurs ! Les parties non personnalisées sont classées, permettant aux joueurs de voir qui sont les 30 premiers et de se fixer des objectifs pour atteindre le sommet.</p>
                  </section>

                  <section className="features">
                      <h2>Caractéristiques actuelles</h2>
                      <ul>
                          <li><strong>Lecture</strong> : Pour le moment, nous proposons la lecture Hafs.</li>
                          <li><strong>Langues</strong> : HAFIDH est disponible en arabe, anglais et français.</li>
                      </ul>
                  </section>
              </div>
            </div>
            <div className="div2props tocenter tocenteroom">
              <div className="boximgold">
                <img alt='' className="imgold2 apparitionq" src={old}></img> 
              </div>
            </div>
            <div className="div3props torightroom"> 
              <img alt='' className="imgroom apparitionq" src={room}></img>
            </div>
            <div className="div4props  touproom">
              <div className="presentation-section notop">
                  
                  <section className="future">
                      <h2>Futur de HAFIDH</h2>
                      <ul>
                          <li><strong>Plus de lectures</strong> : Ajout des lectures Warsh et Qalun.</li>
                          <li><strong>Plus de langues</strong> : Nous travaillons pour intégrer davantage de langues afin d'atteindre un public encore plus large.</li>
                          <li><strong>Nouvelles mécaniques de jeu</strong> : Nous sommes en train de développer de nouvelles façons passionnantes de jouer et d'apprendre.</li>
                          <li><strong>Outils d'apprentissage</strong> : Des outils supplémentaires pour faciliter votre parcours d'apprentissage du Coran.</li>
                      </ul>
                  </section>

                  <section className="mission">
                      <h2>Notre Mission</h2>
                      <p>Notre mission est simple : rendre l'apprentissage du Coran aussi amusant qu'enrichissant. Nous croyons que la combinaison du jeu et de l'éducation peut créer des moments mémorables et significatifs pour nos utilisateurs.</p>
                  </section>
              </div>
            </div>
          </div>
        </div>
        
        <div className="boxparent2 d768">
          
          <div className="lignetype1">
            <div className="accueiltext">
              <div className="presentation-section notop">

                <section className="introduction">
                    <h2>Introduction</h2>
                    <p>HAFIDH est un jeu éducatif conçu pour encourager l'interaction avec le Coran. Notre objectif est d'inspirer les joueurs à lire le Coran plus souvent et à l'apprendre régulièrement à travers une expérience ludique et stimulante.</p>
                </section>

                <section className="how-it-works">
                    <h2>Comment ça marche ?</h2>
                    <ul>
                        <li><strong>Trouver la sourate et le verset mystère</strong> : Identifiez la sourate et le verset, le tout sous la pression d'un compte à rebours.</li>
                        <li><strong>Identifier la sourate</strong> : Si vous connaissez bien vos sourates, testez votre mémoire en essayant de reconnaître la sourate à partir d'un verset spécifique.</li>
                        <li><strong>Mode personnalisé</strong> : Créez des parties personnalisées avec un temps choisi. Bien que ces parties ne soient pas classées, elles offrent une expérience sur mesure pour approfondir votre relation avec le Coran.</li>
                    </ul>
                </section>

                <section className="rankings">
                    <h2>Classements</h2>
                    <p>Mesurez-vous aux autres joueurs ! Les parties non personnalisées sont classées, permettant aux joueurs de voir qui sont les 30 premiers et de se fixer des objectifs pour atteindre le sommet.</p>
                </section>

                <section className="features">
                    <h2>Caractéristiques actuelles</h2>
                    <ul>
                        <li><strong>Lecture</strong> : Pour le moment, nous proposons la lecture Hafs.</li>
                        <li><strong>Langues</strong> : HAFIDH est disponible en arabe, anglais et français.</li>
                    </ul>
                </section>
              </div>
              <div className="a1024f">
                <img alt='' className="imgroom apparitionq" src={room}></img>
                <div className="centerco">
                  <div className="presentation-section textaccueil2">

                    <section className="introduction">
                        <h2>Introduction</h2>
                        <p>HAFIDH est un jeu éducatif conçu pour encourager l'interaction avec le Coran. Notre objectif est d'inspirer les joueurs à lire le Coran plus souvent et à l'apprendre régulièrement à travers une expérience ludique et stimulante.</p>
                    </section>

                    <section className="how-it-works">
                        <h2>Comment ça marche ?</h2>
                        <ul>
                            <li><strong>Trouver la sourate et le verset mystère</strong> : Identifiez la sourate et le verset, le tout sous la pression d'un compte à rebours.</li>
                            <li><strong>Identifier la sourate</strong> : Si vous connaissez bien vos sourates, testez votre mémoire en essayant de reconnaître la sourate à partir d'un verset spécifique.</li>
                            <li><strong>Mode personnalisé</strong> : Créez des parties personnalisées avec un temps choisi. Bien que ces parties ne soient pas classées, elles offrent une expérience sur mesure pour approfondir votre relation avec le Coran.</li>
                        </ul>
                    </section>

                    <section className="rankings">
                        <h2>Classements</h2>
                        <p>Mesurez-vous aux autres joueurs ! Les parties non personnalisées sont classées, permettant aux joueurs de voir qui sont les 30 premiers et de se fixer des objectifs pour atteindre le sommet.</p>
                    </section>

                    <section className="features">
                        <h2>Caractéristiques actuelles</h2>
                        <ul>
                            <li><strong>Lecture</strong> : Pour le moment, nous proposons la lecture Hafs.</li>
                            <li><strong>Langues</strong> : HAFIDH est disponible en arabe, anglais et français.</li>
                        </ul>
                    </section>
                  </div>


                </div>
              </div>

            </div>

          </div>
          <div className="lignetype2">
            <div className="center"><div className="boxdemiimage"><img alt='' className="imgroomsmall apparitionq" src={room}></img></div><div className="boxdemiimage"><img alt='' className="imgoldsmall" src={old}></img></div></div>
            <div className="accueiltext centerco">
              <div className="presentation-section">
                  
                  <section className="future">
                      <h2>Futur de HAFIDH</h2>
                      <ul>
                          <li><strong>Plus de lectures</strong> : Ajout des lectures Warsh et Qalun.</li>
                          <li><strong>Plus de langues</strong> : Nous travaillons pour intégrer davantage de langues afin d'atteindre un public encore plus large.</li>
                          <li><strong>Nouvelles mécaniques de jeu</strong> : Nous sommes en train de développer de nouvelles façons passionnantes de jouer et d'apprendre.</li>
                          <li><strong>Outils d'apprentissage</strong> : Des outils supplémentaires pour faciliter votre parcours d'apprentissage du Coran.</li>
                      </ul>
                  </section>

                  <section className="mission">
                      <h2>Notre Mission</h2>
                      <p>Notre mission est simple : rendre l'apprentissage du Coran aussi amusant qu'enrichissant. Nous croyons que la combinaison du jeu et de l'éducation peut créer des moments mémorables et significatifs pour nos utilisateurs.</p>
                  </section>
              </div>

            </div>

          </div>
        </div>
        <div className="lignebase lignepp">
          <div className="exit">
            <a target="_blank" rel="noopener noreferrer" href="https://ummah-tech.com/"><img alt='' className="imgmachine pointer bigger" src={machine}></img></a>
          </div>
          <div className="btext">
            <div className="presentation-section">
              <section className="astro-num">
                <h2 className="center"><a className="center" target="_blank" rel="noopener noreferrer" href="https://ummah-tech.com/"><img alt='' style={{width:"1em",marginRight:"0.5em"}} className="pointer bigger" src={machine}></img></a>UMMAH TECH</h2>
                <p>
                    UMMAH TECH est une organisation composé de frères et sœurs musulmans. Notre objectif principal est de répondre aux besoins numériques des différents membres de la ummah. Nous offrons une gamme variée de services tels que la création de sites web, applications mobiles, plateformes, logiciels, logos, flyers, et même la génération d'images par IA, parmi tant d'autres.
                </p>
                <p>
                    De plus, nous ne nous limitons pas seulement à fournir des services. UMMAH TECH est également fier de présenter ses propres projets, spécifiquement conçus pour faciliter et optimiser la vie quotidienne des musulmans.
                </p>
                <p>
                    Pour en savoir plus sur nous et découvrir l'étendue de nos services et projets, n'hésitez pas à visiter notre <a className="bigger" style={{color:"#2c8bdd"}} href="https://ummah-tech.com/" target="_blank" rel="noopener noreferrer">site web</a>.
                </p>
              </section>
            </div>
          </div>
        </div>
        <Partenaires></Partenaires>
        <div style={{height:"5em"}}>
          <div /*className="circleforsvg"*/>

          </div>
        </div>
      </section>
      <div style={{margin:"-7em"}}></div>
      <Help></Help>
    </>
  );
}
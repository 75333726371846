import './App.css';
import './Hafizh.css';
import Cadre from './Components/Cadre.js'
import Accueil from './Pages/Accueil'
import Propos from './Pages/Propos'
import Contact from './Pages/Contact'
import Connexion from './Pages/Connexion'
import Lecture from './Pages/Lecture.js'
import Game from './Pages/Game'
import Play from './Pages/PagesGame/Play'
import Reglage from './Pages/PagesGame/Reglage'
import Classement from './Pages/PagesGame/Classement'
import AddGame from './Pages/PagesGame/AddGame'
import Home from './Pages/PagesGame/Home'
import ListGame from './Pages/PagesGame/ListGame'
import Verse from './Pages/Verse'
import Verse2 from './Pages/Verse2'
import Basmalah from './Pages/Basmalah.js'
import Istiadha from './Pages/Istiadha.js'
import Basmalah2 from './Pages/Basmalah2.js'
import Istiadha2 from './Pages/Istiadha2.js'
import Surat from './Pages/Surat'
//import Rub from './Pages/Rub.js'
import Error404 from './Pages/Error404.js'
import Error from './Pages/Error.js'
import ContextProvider from './context/ContextProvider';

import {Route, Routes} from 'react-router-dom'



function App() {
  return (
    <>

    <ContextProvider>
      {/*<Nav />*/}
      <Cadre>
        <Routes>
          <Route path="/"  element={<Accueil/>}/>
          <Route path="/a-propos"  element={<Propos/>}/>
          <Route path="/contact"  element={<Contact/>}/>
          <Route path="/connexion"  element={<Connexion/>}/>
          <Route path="/game"  element={<Game><Home/></Game>}/>
          <Route path="/game/play/:id"  element={<Game><Play/></Game>}/>
          <Route path="/game/list-game"  element={<Game><ListGame/></Game>}/>
          <Route path="/game/new-game"  element={<Game><AddGame/></Game>}/>
          <Route path="/game/classement"  element={<Game><Classement/></Game>}/>
          <Route path="/game/reglage"  element={<Game><Reglage/></Game>}/>
          <Route path="/lecture"  element={<Lecture></Lecture>}/>
          <Route path="/verse/:id" exact  element={<Verse/>}/>
          <Route path="/verse/:surat/:verse" exact  element={<Verse2/>}/>
          <Route path="/basmalah/:id" exact  element={<Basmalah/>}/>
          <Route path="/istiadha/:id" exact  element={<Istiadha/>}/>
          <Route path="/basmalah/:surat/:verse" exact  element={<Basmalah2/>}/>
          <Route path="/istiadha/:surat/:verse" exact  element={<Istiadha2/>}/>
          <Route path="/surat/:id" exact  element={<Surat/>}/>
          <Route path="/surat/:id/:firstverse" exact  element={<Surat/>}/>
          {/*<Route path="/rub/:id" exact  element={<Rub/>}/>*/}



          <Route path="/error"  element={<Error/>}/>
          <Route path="*" element={<Error404/>}/>
        </Routes>
      </Cadre>
    </ContextProvider>
    </>
  );
}

export default App;

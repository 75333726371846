import React, {useContext , useEffect , useState,useCallback} from "react";
import {Context} from '../../context/ContextProvider';
import './../Page.css';
import { useNavigate ,NavLink } from "react-router-dom";
import { checkcon } from "../../actions/user.js";
import Arrow from './../../items/Image/arrow3.svg';
import $ from "jquery";




export default function Classement() {
  //const {connected, updateconnected} = useContext(Context);
  const {connected} = useContext(Context);
  const navigate = useNavigate();
  const [gamesData, setGamesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [suratInfo, setSuratInfo] = useState({});
  const [numfrange, setnumfrange] = useState(0);
  const [maxfrange, setmaxfrange] = useState(0);
  const [frange, setfrange] = useState([]);




  const getSuratInfoApi=()=>{
    //const data = { UserId: 1,Game:id};


    fetch('https://hafiz-quran.com/express/suratinfo', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
    .then((response) => response.json())
    .then((data) => {
      setSuratInfo(data);
    },

      (error) => {
        console.log("Pas de Connexion");
      }

    );
  }
    useEffect(() => {

        getSuratInfoApi();

        return () => {
        }
    }, []);

    let datal=""
    if(suratInfo[1]){
        for(let i=0; i<Object.keys(suratInfo).length; i++){
            datal=datal+"<option value="+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" >"+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Francais"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Arabe"]+"</option>";
        }
    }
    const changeInputSurat=()=>{
      let valeur = $("#surat").val();
      if($("#choice").val()==="Surat" && Number.isInteger(+valeur) && +valeur >= 1 && +valeur <= 114){
        classementApi($("#choice2").val(),"Surat",+valeur,30);
      }
    }
    const changeInputRub=()=>{
      let valeur = $("#rub").val();
      if($("#choice").val()==="Rub" && Number.isInteger(+valeur) && +valeur >= 1 && +valeur <= 240){
        classementApi($("#choice2").val(),"Rub",+valeur,30);
      }
    }
    const changeInputHizb=()=>{
      let valeur = $("#hizb").val();
      if($("#choice").val()==="Hizb" && Number.isInteger(+valeur) && +valeur >= 1 && +valeur <= 60){
        classementApi($("#choice2").val(),"Hizb",+valeur,30);
      }
    }
    const changeInputJuz=()=>{
      let valeur = $("#juz").val();
      if($("#choice").val()==="Juz" && Number.isInteger(+valeur) && +valeur >= 1 && +valeur <= 30){
        classementApi($("#choice2").val(),"Juz",+valeur,30);
      }
    }
    const changeChoice=()=>{
        if($("#choice").val()==="All"){
            $("#txtinput").addClass("exit");
            $("#juz").addClass("exit");
            $("#hizb").addClass("exit");
            $("#rub").addClass("exit");
            $("#surat").addClass("exit");
            $("#juztxtinput").addClass("exit");
            $("#hizbtxtinput").addClass("exit");
            $("#rubtxtinput").addClass("exit");
            $("#surattxtinput").addClass("exit");

        }
        else if($("#choice").val()==="Juz"){
            $("#txtinput").removeClass("exit");
            $("#juz").removeClass("exit");
            $("#hizb").addClass("exit");
            $("#rub").addClass("exit");
            $("#surat").addClass("exit");
            $("#juztxtinput").removeClass("exit");
            $("#hizbtxtinput").addClass("exit");
            $("#rubtxtinput").addClass("exit");
            $("#surattxtinput").addClass("exit");

        }
        else if($("#choice").val()==="Hizb"){
            $("#txtinput").removeClass("exit");
            $("#juz").addClass("exit");
            $("#hizb").removeClass("exit");
            $("#rub").addClass("exit");
            $("#surat").addClass("exit");
            $("#juztxtinput").addClass("exit");
            $("#hizbtxtinput").removeClass("exit");
            $("#rubtxtinput").addClass("exit");
            $("#surattxtinput").addClass("exit");

        }
        else if($("#choice").val()==="Rub"){
            $("#txtinput").removeClass("exit");
            $("#juz").addClass("exit");
            $("#hizb").addClass("exit");
            $("#rub").removeClass("exit");
            $("#surat").addClass("exit");
            $("#juztxtinput").addClass("exit");
            $("#hizbtxtinput").addClass("exit");
            $("#rubtxtinput").removeClass("exit");
            $("#surattxtinput").addClass("exit");

        }
        else{
            $("#txtinput").addClass("exit");
            $("#juz").addClass("exit");
            $("#hizb").addClass("exit");
            $("#rub").addClass("exit");
            $("#surat").removeClass("exit");
            $("#juztxtinput").addClass("exit");
            $("#hizbtxtinput").addClass("exit");
            $("#rubtxtinput").addClass("exit");
            $("#surattxtinput").removeClass("exit");

        }
        
    }       

  const goConnexion = useCallback((bool) => {
      if (!bool) {
          navigate("/connexion", { connection: true });
      }
  }, [navigate]); 

  const ChangeClasse = ()=>{
    if($("#choice").val()==="All"){
      classementApi($("#choice2").val(),"All",1,30);
    }
    else if($("#choice").val()==="Surat" && Number.isInteger(+$("#surat").val()) && +$("#surat").val() >= 1 && +$("#surat").val() <= 114){
        classementApi($("#choice2").val(),"Surat",+$("#surat").val(),30);
    }
    else if($("#choice").val()==="Rub" && Number.isInteger(+$("#rub").val()) && +$("#rub").val() >= 1 && +$("#rub").val() <= 240 ){
        classementApi($("#choice2").val(),"Rub",+$("#rub").val(),30);
    }
    else if($("#choice").val()==="Hizb" && Number.isInteger(+$("#hizb").val()) && +$("#hizb").val() >= 1 && +$("#hizb").val() <= 60 ){

        classementApi($("#choice2").val(),"Hizb",+$("#hizb").val(),30);
      
    }
    else if($("#choice").val()==="Juz" && Number.isInteger(+$("#juz").val()) && +$("#juz").val() >= 1 && +$("#juz").val() <= 30){

        classementApi($("#choice2").val(),"Juz",+$("#juz").val(),30);
    
    }
    else{
      setGamesData([]);
      setnumfrange(0);
      setmaxfrange(0);
      setfrange([]);
    }
  }

  useEffect(
    () => {

        if(!connected){
            checkcon(goConnexion);
            //navigate("/connexion", { connection: true });
        }
        else if(connected){
        }

      return () => {
      }

    },
    [connected,goConnexion],
  );


    const classementApi=(Version,Type,Typenumber,limit)=>{
      const data = { Version,Type , Typenumber , limit};
  
  
      fetch('https://hafiz-quran.com/express/get-top-games', {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response) => response.json())
      .then((data) => {
        setGamesData(data.result);
        setLoading(false);
      },
  
        (error) => {
          console.log("Pas de Connexion");
          setError(error.message);
          setLoading(false);
        }
  
      );
    }
    const PreviousFrange =()=>{

      if(numfrange>0){
        setfrange( gamesData.slice((numfrange-1)*10, (numfrange-1)*10+10));
        setnumfrange(numfrange-1);
      }
    }
    const NextFrange =()=>{
      if(numfrange<maxfrange && maxfrange>0 && numfrange>=0){
        setfrange( gamesData.slice((numfrange+1)*10, (numfrange+1)*10+10));
        setnumfrange(numfrange+1);
      }
    }

    useEffect(() => {
        classementApi("Classique","All",1,30);
    }, []);

    useEffect(()=>{
      setnumfrange(0);
      const numberOfElements = gamesData.length;
      setmaxfrange(Math.ceil(numberOfElements / 10)-1);
      setfrange( gamesData.slice(0, 10));

    },[gamesData])

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  return (
    <>
    <div className="centerco apparitionq">
    <h1 className="talign">Classement</h1>
    <p className="centerco">
            <label className="talign" htmlFor="choice">Sur quel partie du Coran voulez vous voir le classement ?</label><br/>
            <select name="choice" className="inp4 inputdefault pointer inpaddgame choiceleader" id="choice" onChange={ChangeClasse} onInput={changeChoice}>
                <option value="All">Tout le Coran</option>
                <option value="Juz">Sur un Juz</option>
                <option value="Hizb">Sur un Hizb</option>
                <option value="Rub">Sur un Rub</option>
                <option value="Surat">Sur une Sourate</option>
            </select>
    </p>
    <p id="txtinput" className="apparitionq exit">Sur lequel d'entre eux ? maximum <span id="juztxtinput" className="exit">30</span><span id="hizbtxtinput" className="exit">60</span><span id="rubtxtinput" className="exit">240</span></p>
        <input type="number" name="juz" id="juz" className="inp3 inputdefault apparitionq pointer exit inpaddgame" min={1} max={30} placeholder="Juz" required="" onInput={changeInputJuz}></input>
        <input type="number" name="hizb" id="hizb" className="inp3 inputdefault apparitionq pointer exit inpaddgame" min={1} max={60} placeholder="Hizb" required="" onInput={changeInputHizb}></input>
        <input type="number" name="rub" id="rub" className="inp3 inputdefault apparitionq pointer exit inpaddgame" min={1} max={240} placeholder="Rub" required="" onInput={changeInputRub}></input>
        <p id="surattxtinput" className="apparitionq exit">Sur laquelle d'entre elle ? maximum 114</p>
        <input id="surat" className="inp1 inputdefault apparitionq pointer talign exit surantinpaddgame" name="surat" placeholder="Surat" list="suratname" onInput={changeInputSurat}></input>
            <datalist id="suratname">

                <div dangerouslySetInnerHTML={{ __html: datal }} />

            </datalist>
        <p className="centerco">
            <label htmlFor="choice2">Sur quel version du jeu ?</label><br/>
            <select name="choice2" className="inp4 inputdefault pointer inpaddgame" id="choice2" onChange={ChangeClasse}>
                <option value="Classique">Classique</option>
                <option value="SuratO">Surat Only</option>
            </select>
        </p>
        <div style={{height:"1em"}}></div>
    { (Array.isArray(gamesData) && gamesData.length > 0) && (
      <>
        {frange.map((game, index) => (
          <div id={"leader"+numfrange+index} className="leaderbox" key={index} >
            <h2 className="numberleader">{numfrange*10+index+1}</h2>
            <div className="boxp">
              {(game.Type==="All")&&(<p className="exit">Sur le Coran Entier </p>)}
              {(game.Type!=="All")&&(<p className="exit">sur le {game.Type} : </p>)}
              {(game.Type!=="All")&&(<p className="exit">Typenumber: {game.Typenumber}</p>)}
              <p>BestScore: <span className="bestscore">{game.BestScore}</span></p>
              <p>Players: {game.PlayerNames.join(', ')}</p>
            </div>
          </div>
        ))}   
      </>
    )}
      <div className="center">
        <button className={numfrange===0 ? "bt0 returngame pointer center" : " returngame pointer center"} /*className="returngame pointer center"*/ onClick={PreviousFrange} ><img alt='joueur précédent' className="smallarrow" src={Arrow}></img></button>
        <button className={(numfrange===maxfrange || maxfrange<=0 ) ? "bt0 returngame pointer center" : " returngame pointer center"} onClick={NextFrange} ><img alt='joueur suivant' className="smallarrow2" src={Arrow}></img></button>
      </div>
      <div style={{height:"2em"}}></div>
    </div>
    <div className="centerco"> 
                <NavLink className={"linkclass"} to="/game/">
                    <button className="returngame pointer center" ><img alt='' className="imglink arrowreturn" src={Arrow}></img>Retour au menu</button>
                </NavLink>
                <NavLink className={"linkclass"} to="/game/list-game">
                    <button className="returngame pointer center" >Reprendre une partie<img alt='' className="imglink arrowreturn2" src={Arrow}></img></button>
                </NavLink>
    </div>
    </>
  );
}

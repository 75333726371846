const fetchData = async (
  { url, method = 'POST', token = '', body = null , isFormData = false },callback
) => {
  let headers = {};
  if (isFormData) {
    if (token) {
      headers = { authorization: `Bearer ${token}` };
    }
  } else {
    headers = token
      ? { 'Content-Type': 'application/json', authorization: `Bearer ${token}` }
      : { 'Content-Type': 'application/json' };
    body = body ? { body: JSON.stringify(body) } : {};
  }
  try {
    const response = await fetch(url, { method, headers, ...body , credentials:'include' });
    const data = await response.json();
    if (!data.success) {
      callback(data)
      if (response.status === 401){
        //throw new Error(data.message);
      }
    }
    else{
      return data.result;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default fetchData;

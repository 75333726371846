import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState , useRef,useContext } from "react";
import './Page.css';
import $ from "jquery";
//import Audio from "../Components/Audioreader/Audio.js";
import AudioBase from "../Components/Audioreader/AudioBase.js";
import AudioOther from "../Components/Audioreader/AudioOther.js";

import {useNavigate ,NavLink  } from "react-router-dom";
//import ToggleSwitch from "../Components/ToggleSwitch/ToggleSwitch";
import ToggleSwitchMultiple from "../Components/ToggleSwitch/ToggleSwitchMultiple";
import Arrow from './../items/Image/arrow3.svg';
//import AudioButton from "../Components/Audioreader/AudioButton";
//import AudioPlayer from "../Components/Audioreader/AudioPlayer";
import Loader from "../Components/Loader/Loader.js";
import {Context} from '../context/ContextProvider';
import {getReglage } from "../actions/user.js";





export default function Surat(props) {
  const {connected} = useContext(Context);

  const {id} = useParams();
  const {firstverse}=useParams();
  //const history = useHistory();
  const navigate = useNavigate();

  const [ListAyah, setListAyah] = useState(false);
  const [AudioUrl, setAudio] = useState("");
  const [SuratName, setSuratName] = useState("");
  const [SuratNameFrancais, setSuratNameFrancais] = useState("");
  const [nointerupt,setnointerupt]=useState(false)
  const [Boucle,setBoucle]=useState(false)

  const [suratInfo, setSuratInfo] = useState({});
  //const [CurrentVerse, setCurrentVerse] = useState(0);

  const audiopRef = useRef(null);

  //const [TheBegin, setTheBegin] = useState(0);
  const [isFixed, setIsFixed] = useState(true);

  const [Listen, setListen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActiveBoucle, setIsActiveBoucle] = useState(false);
  const [Reglage,setReglage]=useState([]);



  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);



  const BUrl="https://hafiz-quran.com/express";

  function NumToDoss(number){
      if(number<10){
        number="00"+number.toString()
      }
      else if(number<100){
        number="0"+number.toString()
      }
      return number.toString();
  }

  useEffect(
    () => {
      if(connected){
        getReglage(setReglage);
      }
      return ()=>{

      }
    },
    [connected],
  );
  useEffect(
    () => {
      if(Object.keys(Reglage).length>=1){
        setLoading2(true);
      }
      else{
        setLoading2(false);
      }
      return ()=>{

      }
    },
    [Reglage],
  );

  const checkScroll = () => {
    const footer = document.getElementById('footer'); // Assurez-vous d'avoir un élément avec l'ID 'footer'
    //const rect = footer.getBoundingClientRect();
    //const boxdown = document.getElementById('boxdown'); // Assurez-vous d'avoir un élément avec l'ID 'footer'
    
    if(footer){
      const rect2 = footer.getBoundingClientRect();
      //console.log(rect2.top);
      const isAboveFooter = Math.floor(rect2.top) > Math.floor(window.innerHeight)+100;
  
      setIsFixed(isAboveFooter);
    }else{
      setIsFixed(true);
    }


  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    //checkScroll(); // Pour vérifier la position initiale

    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  useEffect(() => {
    if(Math.floor(id)&& Math.floor(id)===9 && Math.floor(firstverse)===0){
      navigate('/surat/9/1', {})
    }
  

    return () => {
      
    };
  }, [id,firstverse,navigate]);


  const PSurat=()=>{
    if(Math.floor(id)<=114 && Math.floor(id)>1){
      /*change here*/
      if(Math.floor(id)!==10){
        navigate('/surat/'+(Math.floor(id)-1).toString()+"/0", {})
      }
      else{
        navigate('/surat/'+(Math.floor(id)-1).toString()+"/1", {})
      }
    }

  }
  const NSurat=()=>{
      if(Math.floor(id)<114 && Math.floor(id)>=1){
        /*change here*/
        if(Math.floor(id)!==8){
          navigate('/surat/'+(Math.floor(id)+1).toString()+"/0", {})
        }
        else{
          navigate('/surat/'+(Math.floor(id)+1).toString()+"/1", {})
        
        }
      }
  }
  const Ecoutecontinue=()=>{
    if(firstverse && Math.floor(firstverse)){
      if(Math.floor(firstverse)<ListAyah.length && Math.floor(firstverse)>=1){
        navigate('/surat/'+id.toString()+'/'+(Math.floor(firstverse)+1).toString(), {})
      }
      else if(Boucle && Math.floor(id)<114 /*change here*/ && Math.floor(id)>=1){
        if(Math.floor(id)!==9){
          navigate('/surat/'+id+'/0', {})
        }
        else{
          navigate('/surat/'+id+'/1', {})
        }
      }
      else if(Math.floor(firstverse)===ListAyah.length && Math.floor(id)<114 && Math.floor(id)>=1){
        /*change here*/
        if(Math.floor(id)!==8){
          navigate('/surat/'+(Math.floor(id)+1).toString()+'/0', {})
        }
        else{
          navigate('/surat/'+(Math.floor(id)+1).toString()+'/1', {})
        }
      }
      else if(Math.floor(firstverse)===ListAyah.length && Math.floor(id)===114 ){
        navigate('/surat/'+1+'/0', {})
      }
    }
    else{
        navigate('/surat/'+id.toString()+'/'+(1).toString(), {})
    }
  }
  


  const GetSurat = (surat) => {
    //alert("okep");


    fetch('https://hafiz-quran.com/express/surat/'+Math.floor(surat).toString(), {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },

    })
    .then((response) => response.json())
    .then((data) => {
      if(!data["Error"]){
        setListAyah(Object.values(data["SuratList"]));
        /*
        let naudio;
        if(id>1 && Math.floor(firstverse)===0){
          naudio=BUrl+"/ayah/"+NumToDoss(1)+"/"+NumToDoss(1)+NumToDoss(1)+".mp3";
        }
        else{
          naudio=BUrl+"/ayah/"+NumToDoss(Math.floor(id))+"/"+NumToDoss(Math.floor(id))+NumToDoss(Math.floor(firstverse))+".mp3";
        }
        //setAudio(data["AudioUrl"]);
        setAudio(naudio);
        */
        setSuratName(data["SuratName"]);
        setSuratNameFrancais(data["SuratNameFrancais"]);
        /*if(firstverse && Math.floor(firstverse)){
          setCurrentVerse(Math.floor(firstverse));
        }
        else{
          setCurrentVerse(0);
        }*/
        setLoading(true);
      }

    },

      (error) => {
        console.log(error);
        console.log("Pas de Connexion");
      }

    );
  }; 



  const changeInput1=()=>{
    if(Math.floor($("#inp1").val())&& Math.floor($("#inp1").val())>=1 && Math.floor($("#inp1").val())<=114){
      $("#nbverse").html(suratInfo[Math.floor($("#inp1").val())-1]["Nbreverset"]);
      $("#verse").attr("max",suratInfo[Math.floor($("#inp1").val())-1]["Nbreverset"]);
    }
    else{
      $("#nbverse").html(0);
      $("#verse").attr("max",1);
    }
  }

  const getSuratInfoApi=()=>{



    fetch('https://hafiz-quran.com/express/suratinfo', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
    .then((response) => response.json())
    .then((data) => {
      setSuratInfo(data);
    },

      (error) => {
        console.log("Pas de Connexion");
      }

    );
  }

  const goToSurat=()=>{
    let valuesurat=Math.floor($("#inp1").val());
    if(valuesurat<=114 && valuesurat>=1 ){
        navigate('/surat/'+(Math.floor(valuesurat)).toString(), {})
    }
  }
  


  useEffect(
    () => {
      $(".boxverse").each(function(){
        $(this).click(function(){
          const number=Math.floor($(this).attr("verse"));
          //setCurrentVerse(number)
          navigate('/surat/'+id.toString()+'/'+(number).toString(), {})
          //setTheBegin(CumulTime[number-1]);

        });
      })
    
      return ()=>{
        $(".boxverse").each(function(){
          $(this).off('click');
        }
      )}
    },

    [ListAyah,id,navigate,loading,loading2],
  );

  useEffect(
    () => {
      
      let nextaudio;
      if(id>1 && Math.floor(firstverse)===0){
        nextaudio=BUrl+"/ayah/"+NumToDoss(1)+"/"+NumToDoss(1)+NumToDoss(1)+".mp3";
      }
      else{
        nextaudio=BUrl+"/ayah/"+NumToDoss(Math.floor(id))+"/"+NumToDoss(Math.floor(id))+NumToDoss(Math.floor(firstverse))+".mp3";
      }

      setAudio(nextaudio)
          
      return ()=>{

      }
    },

    [firstverse,id],
  );


  useEffect(
    () => {
      

      if(id && Math.floor(id) && firstverse && Math.floor(firstverse)){/*change here peut être*/
        if(loading && (!connected || loading2 ) && Math.floor(firstverse)<=ListAyah.length && Math.floor(firstverse)>=0){
          if(Math.floor(id)!==9){
            navigate('/surat/'+id+'/'+(Math.floor(firstverse)).toString(), {});
            let logoPosition = $("#boxverse"+(Math.floor(firstverse)-1).toString()).offset().top + $("#boxverse"+(Math.floor(firstverse)-1).toString()).height();
            //window.scrollTo(0, logoPosition);
            $("html, body").animate({ scrollTop: logoPosition }, 500);
          }
          else{
            if(Math.floor(firstverse)>=2){
              navigate('/surat/'+id+'/'+(Math.floor(firstverse)).toString(), {});
              let logoPosition = $("#boxverse"+(Math.floor(firstverse)-1).toString()).offset().top + $("#boxverse"+(Math.floor(firstverse)-1).toString()).height();
              //window.scrollTo(0, logoPosition);
              $("html, body").animate({ scrollTop: logoPosition }, 500);
            }
            else if(Math.floor(firstverse)===1){
              navigate('/surat/'+id+'/'+(Math.floor(firstverse)).toString(), {});
              $("html, body").animate({ scrollTop: 0}, 500);

            }
            else{
              navigate('/surat/'+id+'/1', {});
            }
          
          }
        }
        else if(loading && (!connected || loading2 )){
          if(Math.floor(id)!==9){
            navigate('/surat/'+id+'/0', {});
            window.scrollTo(0, 0);
          }
          else{
            navigate('/surat/'+id+'/1', {});
            window.scrollTo(0, 0);
          }
        }

      }
      else if(id && Math.floor(id) && firstverse && Math.floor(firstverse)===0){
        if(loading && (!connected || loading2 )) {
          navigate('/surat/'+id+'/0', {});
          window.scrollTo(0, 0);
        }

      }
      else if(id && Math.floor(id)){
        if(loading && (!connected || loading2 )) {
          navigate('/surat/'+id+'/0', {});
          window.scrollTo(0, 0);
        }
      }
      else{
        navigate('/surat/1/0', {});
        //let logoPosition = $("#boxsurattitle").offset().top;
        window.scrollTo(0, 0);
        /*
          if(loading && id && Math.floor(id)){
            navigate('/surat/'+id+'/0', {});
            //let logoPosition = $("#boxsurattitle").offset().top;
            window.scrollTo(0, 0);
          }
          else{
            navigate('/surat/1/0', {});
            //let logoPosition = $("#boxsurattitle").offset().top;
            window.scrollTo(0, 0);
          }
        */

      }
    
      return ()=>{

      }
    },

    [id,ListAyah.length,firstverse,loading , connected,loading2,navigate],
  );

  useEffect(
    () => {
      if(Math.floor(id)>114 || Math.floor(id)<1){
        //history.push("/error-id")
        navigate("/error-id", { replace: true })
      }
      else{
        GetSurat(id);
      }

      getSuratInfoApi();

        $(window).keyup(function(event){
          
            if(event.which===229){
              let btnc10 = document.querySelector(".allbuttons:first-child");
                          
              if (btnc10) {
              btnc10.click();
              }
              else {
              console.log("Error");
              }
    
    
        }
        if(event.which===37){
            let btnc1 = document.querySelector("#previousverse");
                        
            if (btnc1) {
            btnc1.click();
            }
            else {
            console.log("Error");
            }
    
    
        }
        if(event.which===39){
          let btnc0 = document.querySelector("#nextverse");
                      
          if (btnc0) {
          btnc0.click();
          }
          else {
          console.log("Error");
          }  
        }
      })
      
    },
    [id,navigate],
  );
  /*
  useEffect(
    () => {
      if(Math.floor(id)>114 || Math.floor(id)<1){
        //history.push("/error-id")
        navigate("/error-id", { replace: true })
      }
      else{
        GetSurat(id);
      }
    },
    [id,navigate],
  );
*/
  

  

  let newclass="cadreafterfixe";
  if(props.classrepair){
    newclass=newclass+" "+props.classrepair
  }
  

  let datal=""
  if(suratInfo[1]){
    for(let i=0; i<Object.keys(suratInfo).length; i++){
      datal=datal+"<option value="+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" >"+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Francais"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Arabe"]+"</option>";

    }
  }

  return (
    <>
      {( loading && (!connected || loading2 ))&&(
      <div className={newclass}>
        <div className="cadreverse apparitionq">
          <div id="boxsurattitle" className="boxsurattitle centerco">
            <h2 style={{marginBottom: "0.3em"}}>Sourate Nº<span>{id}</span></h2>
            <h2 className="margin0"><span>{SuratName}</span> / <span>{SuratNameFrancais}</span></h2>
          </div>
          <div style={{marginBottom:"2em"}}></div>
          {Math.floor(id) && Math.floor(id)===1 && (
          <div id={"boxverse"+0} className={Math.floor(firstverse)===0?"centerco boxverse pointer thisverse":"centerco boxverse pointer "}  verse={0}  /*onClick={()=>setCurrentVerse(Math.floor(indexayah)+1)}*/ >
                {(!connected ||Reglage.ArabicLecture)&&(<p  className=" ayah verse center talign-end">أَعُوذُ بِاللَّـهِ مِنَ الشَّيْطَانِ الرَّجِيمِ </p>)}
                {(!connected ||Reglage.FrenchLecture)&&(<p  className=" ayahfrench verse center talign-start">{"Je cherche refuge auprès d’Allah contre satan le maudit "}</p>)}
                {(!connected ||Reglage.EnglishLecture)&&(<p className=" ayahfrench verse center talign-start">{"I seek protection with Allah from Shaytan the cursed"}</p>)}
                <div style={{margin:"0.5em"}}></div>
          </div>
          )}
          {Math.floor(id) && Math.floor(id)>1 && Math.floor(id)!==9 && (/*change here*/
            <div id={"boxverse"+0} className={Math.floor(firstverse)===0?"centerco boxverse pointer thisverse":"centerco boxverse pointer "}  verse={0}  /*onClick={()=>setCurrentVerse(Math.floor(indexayah)+1)}*/ >
                  
                  {(!connected ||Reglage.ArabicLecture)&&(<p  className=" ayah verse center talign-end exit"> أَعُوذُ بِاللَّـهِ مِنَ الشَّيْطَانِ الرَّجِيمِ </p>)}
                  {(!connected ||Reglage.ArabicLecture)&&(<p  className=" ayah verse center talign-end"> بِسْمِ اللَّهِ الرَّحْمَٰنِ الرَّحِيمِ </p>)}

                  {(!connected ||Reglage.FrenchLecture)&&(<p  className=" ayahfrench verse center talign-start exit">{"Je cherche refuge auprès d’Allah contre satan le maudit "}</p>)}
                  {(!connected ||Reglage.FrenchLecture)&&(<p  className=" ayahfrench verse center talign-start ">{"Au nom d´Allah, le Tout Miséricordieux, le Très Miséricordieux."}</p>)}

                  {(!connected ||Reglage.EnglishLecture)&&(<p className=" ayahfrench verse center talign-start exit">{"I seek protection with Allah from Shaytan the cursed"}</p>)}
                  {(!connected ||Reglage.EnglishLecture)&&(<p className=" ayahfrench verse center talign-start ">{"In the Name of Allah, the Most Beneficent, the Most Merciful."}</p>)}

                  <div style={{margin:"0.5em"}}></div>
            </div>
          )}
          {ListAyah && Array.isArray(ListAyah) && ListAyah.length > 0 && (ListAyah.map((ayah, indexayah) => (
              <div id={"boxverse"+(Math.floor(indexayah)+1)} className={Math.floor(firstverse)===(Math.floor(indexayah)+1)?"centerco boxverse pointer thisverse":"centerco boxverse pointer "} key={indexayah} verse={Math.floor(indexayah)+1}  /*onClick={()=>setCurrentVerse(Math.floor(indexayah)+1)}*/ >
                <h4 style={{marginBottom: "0.3em"}}>Verset Nº<span>{ayah.VerseID}</span></h4>
                {(!connected || Reglage.ArabicLecture)&&(<p  className=" ayah verse center talign-end">{ayah.Ayah}</p>)}
                {(!connected || Reglage.FrenchLecture)&&(<p  className=" ayahfrench verse center talign-start">{ayah.Français}</p>)}
                {(!connected || Reglage.EnglishLecture)&&(<p className=" ayahfrench verse center talign-start">{ayah.English}</p>)}
                <div style={{margin:"0.5em"}}></div>
              </div>
          )))}
          {/*<p id="ayah" className=" ayah verse center talign-end">{Ayah}</p>
          <p id="ayah" className=" ayahfrench verse center talign-start">{Français}</p>
          <p id="ayah" className=" ayahfrench verse center talign-start">{English}</p>*/}

        </div>
        <div className={isFixed ? 'centercostart barredubas apparitionq' : 'centercostart apparitionq exit'} >
          {<div style={{height:"1.5em"}}></div>}
          <AudioOther noTitle={true} audio1={AudioUrl} dontStop={true} EndBool={nointerupt} fonctionEnd={Ecoutecontinue} /*Begin={TheBegin}*/ audiopRef={audiopRef} Listen={Listen} setListen={setListen} className="repairaudio"/>
          <div className="center">Audio en continue : <ToggleSwitchMultiple first={nointerupt} fonct={(result)=>{setnointerupt(result)}} isActive={isActive} setIsActive={setIsActive}  ></ToggleSwitchMultiple></div>
          <div className="center">Lecture en boucle : <ToggleSwitchMultiple first={Boucle} fonct={(result)=>{setBoucle(result)}} isActive={isActiveBoucle} setIsActive={setIsActiveBoucle}  ></ToggleSwitchMultiple></div>


          <div className="center apparitionq">
                    <button id="previousverse" className={Math.floor(id)===1 ? "bt0 returngame pointer center" : " returngame pointer center"} /*className="returngame pointer center"*/ onClick={PSurat} ><img  alt='verset precedent' className="smallarrow" src={Arrow}></img></button>
                    <button id="nextverse" className={Math.floor(id)===6236 ? "bt0 returngame pointer center" : " returngame pointer center"} onClick={NSurat} ><img alt='verset suivant' className="smallarrow2" src={Arrow}></img></button>
          </div>
          <div className="centerco"> 
                <NavLink className={"linkclass"} to="/lecture/">
                    <button className="returngame pointer center" ><img alt='' className="imglink arrowreturn" src={Arrow}></img>Retour au menu lecture</button>
                </NavLink>
          </div>
          {/*<div style={{height:"3em"}}></div>*/}
          <div className="centerco manette apparitionq exit">
                <div className="centrer talign">
                  <input id="inp1" className="inp1 inputdefault" name="surat" placeholder="Surat" list="suratname" onInput={changeInput1}></input>
                    <datalist id="suratname">

                      <div dangerouslySetInnerHTML={{ __html: datal }} />

                    </datalist>                  

                </div>
                <p>Nombre de versets dans la sourate choisis : <span id="nbverse">{ListAyah.length}</span></p>
                <button id="btnsend" className="btn btn3" onClick={goToSurat} >Aller à cette Surat</button>
          </div>
        </div>
        <div id="boxdown" className='centercostart apparitionq' >
          <AudioBase audio1={AudioUrl} noTitle={true} dontStop={true} EndBool={nointerupt} fonctionEnd={Ecoutecontinue} /*Begin={TheBegin}*/ audiopRef={audiopRef} Listen={Listen} setListen={setListen}  className="repairaudio"/>
          <div className="center">Audio en continue : <ToggleSwitchMultiple first={nointerupt} fonct={(result)=>{setnointerupt(result)}} isActive={isActive} setIsActive={setIsActive} ></ToggleSwitchMultiple></div>
          <div className="center">Lecture en boucle : <ToggleSwitchMultiple first={Boucle} fonct={(result)=>{setBoucle(result)}} isActive={isActiveBoucle} setIsActive={setIsActiveBoucle}  ></ToggleSwitchMultiple></div>

          <div className="center apparitionq">
                    <button id="previousverse" className={Math.floor(id)===1 ? "bt0 returngame pointer center" : " returngame pointer center"} /*className="returngame pointer center"*/ onClick={PSurat} ><img  alt='verset precedent' className="smallarrow" src={Arrow}></img></button>
                    <button id="nextverse" className={Math.floor(id)===6236 ? "bt0 returngame pointer center" : " returngame pointer center"} onClick={NSurat} ><img alt='verset suivant' className="smallarrow2" src={Arrow}></img></button>
          </div>
          <div style={{height:"3em"}}></div>
          <div className="centerco manette apparitionq exit">
                <div className="centrer talign">
                  <input id="inp1" className="inp1 inputdefault" name="surat" placeholder="Surat" list="suratname" onInput={changeInput1}></input>
                    <datalist id="suratname">

                      <div dangerouslySetInnerHTML={{ __html: datal }} />

                    </datalist>                  

                </div>
                <p>Nombre de versets dans la sourate choisis : <span id="nbverse">{ListAyah.length}</span></p>
                <button id="btnsend" className="btn btn3" onClick={goToSurat} >Aller à cette Surat</button>
          </div>
          <div className="centerco"> 
                <NavLink className={"linkclass"} to="/lecture/">
                    <button className="returngame pointer center" ><img alt='' className="imglink arrowreturn" src={Arrow}></img>Retour au menu lecture</button>
                </NavLink>
          </div>
        </div>
        <div style={{height:"4em"}}></div>
      </div>
      )}

      {!loading&&(
        <Loader></Loader>
      )}

    </>
  );
}
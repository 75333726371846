import React, {useRef,useState} from "react";
import { useEffect} from "react";
import './Page.css';
import $ from "jquery";
import Help from "../Components/Help";
import Pub from "../Components/Pub/Pub";





export default function Contact(props) {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const MessageRef = useRef(null);
  const [messageErr, setmessageErr]=useState(false);
  const [messageGood, setmessageGood]=useState(false);



  const handleSubmit = (e) => {
    e.preventDefault();
    const name = nameRef.current.value? nameRef.current.value : null;
    const email = emailRef.current.value? emailRef.current.value : null;
    const message = MessageRef.current.value? MessageRef.current.value : null;
  
    // Création de l'objet à envoyer
    if(name && email && message){
    const dataToSend = {
      Nom: name,
      Mail: email,
      Message: message
    };

        fetch('https://hafiz-quran.com/express/mail', {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataToSend),
        })
        .then((response) => response.json())
        .then((data) => {
          if(data.success){
            setmessageErr(false);
            setmessageGood(data.result.message);
            alert(data.result.message);
          }
          else{
            setmessageGood(false);
            setmessageErr(data.result.message);
          }
        },
    
          (error) => {
            console.log("Pas de Connexion");
          }
    
        );
    }
    
  };

  useEffect(
    () => {
      $("#logo").addClass("exit");
      $("#submitboxco2").click(function(){
        $(".inp2").each(function(){
            $(this).addClass("inp2in");
        })
      })
   
      return () => {
        $("#logo").removeClass("exit");
      }

    },
    [],
  );

  return (
    <>
      <div className="margincontacttop"></div>
      <div className="enpar3">
        <div className="par1 part">
            <Pub pubNumber={1} Type={""} Exist={false} />
        </div>
        <div className='centercol apparitionq par0 centercostart'>

          <section id="contact" className="bigbox apparition">
            <h1 className="titlepage titleaccueil">Contact</h1>
            <div className="boxform">
                    <form className="formboxco pastouche" onSubmit={handleSubmit}>
                        <>
                        {props.errorMessage&&(<p className="errorm pastouche">{props.errorMessage}</p>)}
                        {messageErr&&(<p className="errorm pastouche">{messageErr}</p>)}


                        <input
                            autoFocus
                            margin="normal"
                            variant="standard"
                            id="namecontact"
                            label="Name"
                            type="text"
                            className="pastouche inp2"
                            placeholder="Nom"
                            ref={nameRef}
                            props={{ minLength: 2 }}
                            required
                        />

                        <input
                            autoFocus={!props.regist}
                            margin="normal"
                            variant="standard"
                            id="emailcontact"
                            className="pastouche inp2"
                            placeholder="Email"
                            label="Email"
                            type="email"
                            
                            ref={emailRef}
                            required
                        />
                        <textarea
                            autoFocus={!props.regist}
                            margin="normal"
                            variant="standard"
                            id="message"
                            className="pastouche inp2 inp2area"
                            placeholder="Message"
                            label="Message"
                            type="message"
                            props={{ minLength: 2 }}

                            ref={MessageRef}
                            required
                        />

                        </>
                        <>
                        {messageGood&&(<p className="goodm pastouche">{messageGood}</p>)}

                        <button id="submitboxco2" type="submit" variant="contained" className="pastouche btn btnboxco1 pointer" >
                            Envoyer
                        </button>
                        </>
                    </form>
            </div>        
          </section>
        </div>
        <div className="par2 part">
            <Pub pubNumber={2} Type={""} Exist={false}  />
        </div>
      </div>
      {(messageGood||messageErr)&&(<div style={{marginTop:"4em"}} className="margincontact"></div>)}
      <Help></Help>
      
    </>
  );
}
import { createSlice } from "@reduxjs/toolkit";

export const onFooterSlice = createSlice({
    name: "onFooter",
    initialState: {
        value: true,
    },
    reducers: {
        setonFooter: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setonFooter } = onFooterSlice.actions;
export default onFooterSlice.reducer;
import fetchData from './utils/fetchData';


//const url = process.env.REACT_APP_SERVER_URL + '/user';
const url = "https://hafiz-quran.com/express";


export const register = async (user,callback,callback2,falsecallback) => {

  const result = await fetchData(
    { url: url + '/register', body: user },falsecallback
  );
  if (result) {
    const localStorageToken = result.token;
    localStorage.setItem('tokencorangame', localStorageToken);
    //localStorage.setItem('idhistorygame', result.id);
    localStorage.setItem('namecorangame', result.name);
    localStorage.setItem('emailcorangame', result.email);
    callback(true);
    callback2(true);
  }


};

export const login = async (user,callback,callback2,falsecallback) => {

  const result = await fetchData({ url: url + '/login', body: user },falsecallback);
  if (result) {
    const localStorageToken = result.token;
    localStorage.setItem('tokencorangame', localStorageToken);
    //localStorage.setItem('idhistorygame', result.id);
    localStorage.setItem('namecorangame', result.name);
    localStorage.setItem('emailcorangame', result.email);
    callback(true);
    callback2(true);
  }

};



export const checkco = async (callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name ){
    const usersend={email,name};
    const result = await fetchData({ url: url + '/check' , token: token, body: usersend });
    if (result) {

      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      
      callback(true);
      //const localStorageToken = result.token;
      //localStorage.setItem('tokencorangame', localStorageToken);
    }
  }
};

export const checkcon = async (callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');


  if(token && email && name ){
    const usersend={email,name};
    const result = await fetchData({ url: url + '/check' , token: token, body: usersend });
    if (result) {


      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);

      callback(true);
      //const localStorageToken = result.token;
      //localStorage.setItem('tokencorangame', localStorageToken);
    }
    else{
      callback(false);
    }

  }
  else{
    callback(false);
  }
};


export const deco = async (callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name ){
    const usersend={email,name};
    const result = await fetchData({ url: url + '/logout' , token: token, body: usersend });
    if (result) {

      const localStorageToken = result.token;
      localStorage.removeItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.removeItem('namecorangame', result.name);
      localStorage.removeItem('emailcorangame', result.email);
      
      callback(false);
      //const localStorageToken = result.token;
      //localStorage.setItem('tokencorangame', localStorageToken);
    }
  }
  callback(false);

};

export const getUserInfo = async (callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name ){
    const usersend={email,name};
    const result = await fetchData({ url: url + '/user-info' , token: token, body: usersend });
    if (result) {

      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      alert(JSON.stringify(result));
      callback(true);
      //const localStorageToken = result.token;
      //localStorage.setItem('tokencorangame', localStorageToken);
    }
  }
};

export const getDate = async (callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');


  if(token && email && name ){
    const usersend={email,name};
    const result = await fetchData({ url: url + '/date' , token: token, body: usersend });    
    if (result) {

      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      callback(result.date);
      //const localStorageToken = result.token;
      //localStorage.setItem('tokencorangame', localStorageToken);
    }
  }
};







export const sendMyGame = async (Version,Type, Typenumber, players ,callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && Version &&Type && (Typenumber||Typenumber===0) && (players || (Array.isArray(players) && players.length === 0))){
    const usersend={email,name,Version ,Type,Typenumber,players};
    const result = await fetchData({ url: url + '/send-my-game' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      callback(result.IDGame);

    }
  }
};

export const sendMyGamePerso = async (Version,Type, Typenumber, players , Timer ,callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && Version &&Type && (Typenumber||Typenumber===0) && (players || (Array.isArray(players) && players.length === 0)) && Timer){
    const usersend={email,name,Version ,Type,Typenumber,players , Timer};
    const result = await fetchData({ url: url + '/send-my-perso-game' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      callback(result.IDGame);

    }
  }
};

export const sendAResp = async (IDGame, Surat , Verse  ,callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && IDGame && Surat && Verse){
    const usersend={email,name,IDGame,Surat,Verse};
    const result = await fetchData({ url: url + '/user-reponse' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      if(result.check){
        callback(result.result);
      }
      else{
        
      }

    }
  }
};
export const sendARespSuratO = async (IDGame, Surat ,callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && IDGame && Surat){
    const usersend={email,name,IDGame,Surat};
    const result = await fetchData({ url: url + '/user-reponse-suratO' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      if(result.check){
        callback(result.result);
      }
      else{
        
      }

    }
  }
};


export const getNewVerse = async (IDGame,callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');
  if(token && email && name && IDGame){
    const usersend={email,name,IDGame};
    const result = await fetchData({ url: url + '/user-new-verse' , token: token, body: usersend });    
    if (result) {

      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      if(result.check){
        callback(result.result);
      }
      else{
        
      }

    }
  }
};

export const Timeleft = async (IDGame,callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');
  if(token && email && name && IDGame){
    const usersend={email,name,IDGame};
    const result = await fetchData({ url: url + '/user-time-game' , token: token, body: usersend });    
    if (result) {

      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      
      callback(result.Time);


    }
  }
};



export const getGameInfo = async (IDGame,callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && IDGame){
    const usersend={email,name,IDGame};
    const result = await fetchData({ url: url + '/user-game-info' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      if(result.check){
        callback(result.result);
      }
      else{

      }

    }
  }
};



export const deleteAgame = async (IDGame ,callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && IDGame){
    const usersend={email,name,IDGame};
    const result = await fetchData({ url: url + '/delete-a-game' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      callback();

    }
  }
};

export const isUserinGame = async (IDGame,callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && IDGame){
    const usersend={email,name,IDGame};
    const result = await fetchData({ url: url + '/check-game' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      if(result.check){
        callback((result.check))
      }
      else{
        callback(false);
      }
    }
  }
};
export const whichVersion = async (IDGame,Version,callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && IDGame){
    const usersend={email,name,IDGame, Version};
    const result = await fetchData({ url: url + '/check-version' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      if(result.Version){
        callback((result.Version))
      }
      else{
        callback(false);
      }
    }
  }
};

export const getListGame = async (callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name){
    const usersend={email,name};
    const result = await fetchData({ url: url + '/list-game' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      callback((result.ListGame))
    }
  }
};

export const getReglage = async (callback) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name){
    const usersend={email,name};
    const result = await fetchData({ url: url + '/get-reglage' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);
      callback((result.Reglage))
    }
  }
};

export const ChangeHideGame = async (IDGame,Hide) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && IDGame && (Hide===false||Hide===true)){
    const usersend={email,name,IDGame,Hide};
    const result = await fetchData({ url: url + '/hide-game' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);

    }
  }
};

export const ChangeArabicGame = async (Arabic) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && (Arabic===false||Arabic===true)){
    const usersend={email,name,Arabic};
    const result = await fetchData({ url: url + '/arabic-game' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);

    }
  }
};

export const ChangeArabicLecture = async (ArabicLecture) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && (ArabicLecture===false||ArabicLecture===true)){
    const usersend={email,name,ArabicLecture};
    const result = await fetchData({ url: url + '/arabic-lecture' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);

    }
  }
};

export const ChangeFrenchGame = async (French) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');
  if(token && email && name && (French===false||French===true)){
    const usersend={email,name,French};
    const result = await fetchData({ url: url + '/french-game' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);

    }
  }
};

export const ChangeFrenchLecture = async (FrenchLecture) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');
  if(token && email && name && (FrenchLecture===false||FrenchLecture===true)){
    const usersend={email,name,FrenchLecture};
    const result = await fetchData({ url: url + '/french-lecture' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);

    }
  }
};

export const ChangeEnglishGame = async (English) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && (English===false||English===true)){
    const usersend={email,name,English};
    const result = await fetchData({ url: url + '/english-game' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);

    }
  }
};
export const ChangeEnglishLecture = async (EnglishLecture) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && (EnglishLecture===false||EnglishLecture===true)){
    const usersend={email,name,EnglishLecture};
    console.log(usersend);
    const result = await fetchData({ url: url + '/english-lecture' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);

    }
  }
};

export const ChangeAudioGame = async (Audio) => {
  const token=localStorage.getItem('tokencorangame');
  const email=localStorage.getItem('emailcorangame');
  const name=localStorage.getItem('namecorangame');

  if(token && email && name && (Audio===false||Audio===true)){
    const usersend={email,name,Audio};
    const result = await fetchData({ url: url + '/audio-game' , token: token, body: usersend });    
    if (result) {
      const localStorageToken = result.token;
      localStorage.setItem('tokencorangame', localStorageToken);
      //localStorage.setItem('idhistorygame', result.id);
      localStorage.setItem('namecorangame', result.name);
      localStorage.setItem('emailcorangame', result.email);

    }
  }
};
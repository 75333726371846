
import React from 'react';



const PasswordField = React.forwardRef((props, ref) => {
  return (
    <input
      autoFocus
      margin="normal"
      variant="standard"
      id={props.id}
      className='pastouche inp inpin'
      placeholder={props.placeholder}
      label="Password"
      type="password"
      ref={ref}
      required
    />
  );
});

export default PasswordField;

import { createSlice } from "@reduxjs/toolkit";

export const onHeaderSlice = createSlice({
    name: "onHeader",
    initialState: {
        value: true,
    },
    reducers: {
        setonHeader: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setonHeader } = onHeaderSlice.actions;
export default onHeaderSlice.reducer;
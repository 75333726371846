import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState , useContext } from "react";
import './Page.css';
import $ from "jquery";
import Audio from "../Components/Audioreader/Audio.js";
import {useNavigate ,NavLink  } from "react-router-dom";
import ToggleSwitch from "../Components/ToggleSwitch/ToggleSwitch";
import Arrow from './../items/Image/arrow3.svg';
import Loader from "../Components/Loader/Loader.js";
import {Context} from '../context/ContextProvider';
import {getReglage } from "../actions/user.js";
import { useLocation } from 'react-router-dom';








export default function Verse(props) {
  const {connected} = useContext(Context);
  const {id} = useParams();
  //const history = useHistory();
  const navigate = useNavigate();

  const [Ayah, setAyah] = useState("");
  const [Français, setFrançais] = useState("");
  const [English, setEnglish] = useState("");
  const [AudioUrl, setAudio] = useState("");
  const [SuratId, setSuratId] = useState(0);
  const [VerseId, setVerseId] = useState(0);
  const [IDLastVerse,setIDLastVerse]=useState(0);
  const [SuratName, setSuratName] = useState("");
  const [SuratNameFrancais, setSuratNameFrancais] = useState("");
  const [nointerupt,setnointerupt]=useState(false)
  const [suratInfo, setSuratInfo] = useState({});
  const [Reglage,setReglage]=useState([]);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [GoDemarre, setGoDemarre] = useState(false);
  const [Lecture, setLecture] = useState(false);
  const [volume, setVolume] = useState(false);
  const [newVolume, setNewVolume] = useState(false);

  let location = useLocation();
  let ContinueB = location.state?.ContinueB;
  let LectureB=location.state?.LectureB;
  let AudioB=location.state?.AudioB;


  useEffect(() => {
    if (ContinueB) {
      console.log(ContinueB);
      setnointerupt(ContinueB);
    }
    if(LectureB){
      setGoDemarre(true);
    }
  }, [ContinueB, setnointerupt, setGoDemarre, LectureB]);

  useEffect(() => {
    if (AudioB) {
      console.log(AudioB);
      setNewVolume(AudioB);
    }
  }, [AudioB]);



  useEffect(
    () => {
      if(connected){
        getReglage(setReglage);
      }
      return ()=>{

      }
    },
    [connected],
  );
  useEffect(
    () => {
      if(Object.keys(Reglage).length>=1){
        setLoading2(true);
      }
      else{
        setLoading2(false);
      }
      return ()=>{

      }
    },
    [Reglage],
  );

  const PVerse=()=>{
    if(Math.floor(id)<=6236 && Math.floor(id)>1){
        if(VerseId===1 && Math.floor(id)!==1236){
          navigate('/basmalah/'+(Math.floor(id)).toString(), { state: { Continue: nointerupt , LectureC:Lecture , AudioC:volume } })
        }
        else{
          navigate('/verse/'+(Math.floor(id)-1).toString(), {})
        }
    }

  }
  const NVerse=()=>{
      if(Math.floor(id)<6236 && Math.floor(id)>=1){
        
        if(Math.floor(id)===Math.floor(IDLastVerse) && Math.floor(id)!==1235){
          navigate('/basmalah/'+(Math.floor(id)+1).toString(), { state: { Continue: nointerupt , LectureC:Lecture , AudioC:volume } })
        }
        else{
          navigate('/verse/'+(Math.floor(id)+1).toString(), {})
        }
      }
  }
  const Ecoutecontinue=()=>{
    var url = window.location.pathname;
    var lastSegment = url.split('/').pop();
    var numberMatch = lastSegment.match(/\d+/g);
    var lastNumber = numberMatch ? numberMatch.pop() : null;
    let numberto=Math.floor(lastNumber);
    if(numberto<6236 && numberto>=1){
      if(numberto===IDLastVerse && numberto!==1235){
        navigate('/basmalah/'+(numberto+1).toString(), { state: { Continue: nointerupt , LectureC:Lecture , AudioC:volume } })
      }
      else{
        navigate('/verse/'+(numberto+1).toString(), {})
      }
    }
  }
  
  const GetVerse =  (verseId) => {
    //alert("okep");


    fetch('https://hafiz-quran.com/express/verse/'+Math.floor(verseId).toString(), {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },

    })
    .then((response) => response.json())
    .then((data) => {
      if(!data["Error"]){
        setAyah(data["Ayah"]);
        setFrançais(data["Français"]);
        setEnglish(data["English"]);
        setAudio(data["AudioUrl"]);
        setSuratId(data["SuratID"]);
        setVerseId(data["VerseID"]);
        setIDLastVerse(data["ID"]-data["VerseID"]+data["NombreDeVersets"]);
        setSuratName(data["SuratName"]);
        setSuratNameFrancais(data["SuratNameFrancais"]);
        setLoading(true);




      }

    },

      (error) => {
        console.log(error);
        console.log("Pas de Connexion");
      }

    );
  };

  const GetVerseAyah = (suratId,verseId,callback) => {
    //alert("okep");


    fetch('https://hafiz-quran.com/express/verse/'+Math.floor(suratId).toString()+'/'+Math.floor(verseId).toString(), {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },

    })
    .then((response) => response.json())
    .then((data) => {
      if(!data["Error"]){
        callback(data["ID"]);
      }

    },

      (error) => {
        console.log(error);
        console.log("Pas de Connexion");
      }

    );
  };

  const changeInput1=()=>{
    if(Math.floor($("#inp1").val())&& Math.floor($("#inp1").val())>=1 && Math.floor($("#inp1").val())<=114){
      $("#nbverse").html(suratInfo[Math.floor($("#inp1").val())-1]["Nbreverset"]);
      $("#verse").attr("max",suratInfo[Math.floor($("#inp1").val())-1]["Nbreverset"]);
    }
    else{
      $("#nbverse").html(0);
      $("#verse").attr("max",1);
    }
  }

  const getSuratInfoApi=()=>{



    fetch('https://hafiz-quran.com/express/suratinfo', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
    .then((response) => response.json())
    .then((data) => {
      setSuratInfo(data);
    },

      (error) => {
        console.log("Pas de Connexion");
      }

    );
  }

  const goToVerse=()=>{
    let valuesurat=Math.floor($("#inp1").val());
    let valueverse=Math.floor($("#verse").val());
    let nubverse=suratInfo[Math.floor($("#inp1").val())-1]["Nbreverset"];
    if(valuesurat<=114 && valuesurat>=1 && valueverse<=nubverse && valueverse>=1){
      GetVerseAyah(valuesurat,valueverse,(result)=>{
        navigate('/verse/'+(Math.floor(result)).toString(), {})
      })
    }
  }

  useEffect(
    () => {
      if(Math.floor(id)>6236 || Math.floor(id)<1){
        //history.push("/error-id")
        navigate("/error-id", { replace: true })
      }
      else{
        GetVerse(id);
      }

      getSuratInfoApi();

        $(window).keyup(function(event){
          
            if(event.which===229){
              let btnc10 = document.querySelector(".allbuttons:first-child");
                          
              if (btnc10) {
              btnc10.click();
              }
              else {
              console.log("Error");
              }
    
    
        }
        if(event.which===37){
            let btnc1 = document.querySelector("#previousverse");
                        
            if (btnc1) {
            btnc1.click();
            }
            else {
            console.log("Error");
            }
    
    
        }
        if(event.which===39){
          let btnc0 = document.querySelector("#nextverse");
                      
          if (btnc0) {
          btnc0.click();
          }
          else {
          console.log("Error");
          }  
        }
      })
      
    },
    [id,navigate],
  );
  useEffect(
    () => {
      if(Math.floor(id)>6236 || Math.floor(id)<1){
        //history.push("/error-id")
        navigate("/error-id", { replace: true })
      }
      else{
        GetVerse(id);
      }
    },
    [id,navigate],
  );

  let newclass="cadreafterfixe";
  if(props.classrepair){
    newclass=newclass+" "+props.classrepair
  }
  

  let datal=""
  if(suratInfo[1]){
    for(let i=0; i<Object.keys(suratInfo).length; i++){
      datal=datal+"<option value="+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" >"+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Francais"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Arabe"]+"</option>";

    }
  }

  return (
    <>
      {( loading && (!connected || loading2 ))&&(
      <div className={newclass}>
        <div className="cadreverse">
          <div className="boxsurattitle centerco">
            <h1 style={{marginBottom: "0.3em"}}>Ayah Nº<span>{id}</span></h1>
            <h4 className="margin0">Surat Nº<span>{SuratId}</span> <span>{SuratName}</span> / <span>{SuratNameFrancais}</span> Verse Nº<span>{VerseId}</span></h4>
          </div>
          <div style={{marginTop:"2em"}}></div>
          {(!connected ||Reglage.ArabicLecture)&&(<p className=" ayah ayahalone verse center talign-end">{Ayah}</p>)}
          {(!connected ||Reglage.FrenchLecture)&&(<p className=" ayahfrench verse center talign-start">{Français}</p>)}
          {(!connected ||Reglage.EnglishLecture)&&(<p className=" ayahfrench verse center talign-start">{English}</p>)}
          <div style={{marginTop:"2em"}}></div>
          <Audio noTitle={true} audio1={AudioUrl} dontStop={true} EndBool={nointerupt} fonctionEnd={Ecoutecontinue} demarrer={GoDemarre} setLecture={setLecture} newVolume={newVolume} setVolume={setVolume} className="repairaudio"/>
          <div className="center">Audio en continue : <ToggleSwitch first={nointerupt} fonct={(result)=>{setnointerupt(result)}}></ToggleSwitch></div>
        </div>
        <div className="center">
                    <button id="previousverse" className={Math.floor(id)===1 ? "bt0 returngame pointer center" : " returngame pointer center"} /*className="returngame pointer center"*/ onClick={PVerse} ><img  alt='verset precedent' className="smallarrow" src={Arrow}></img></button>
                  <button id="nextverse" className={Math.floor(id)===6236 ? "bt0 returngame pointer center" : " returngame pointer center"} onClick={NVerse} ><img alt='verset suivant' className="smallarrow2" src={Arrow}></img></button>
        </div>
        <div style={{height:"3em"}}></div>
        <div className="centerco manette exit">
              <div className="centrer talign">
                <input id="inp1" className="inp1 inputdefault" name="surat" placeholder="Surat" list="suratname" onInput={changeInput1}></input>
                  <datalist id="suratname">

                    <div dangerouslySetInnerHTML={{ __html: datal }} />

                  </datalist>
                  
                  <input type="number" name="verse" id="verse" className="inp3 inputdefault" min={1} max={1} placeholder="Verse" required=""></input>
                  

              </div>
              <p>Nombre de versets dans la sourate choisis : <span id="nbverse">0</span></p>
              <button id="btnsend" className="btn btn3" onClick={goToVerse} >Aller à ce verset</button>
        </div>
        <div className="centerco"> 
                <NavLink className={"linkclass"} to="/lecture/">
                    <button className="returngame pointer center" ><img alt='' className="imglink arrowreturn" src={Arrow}></img>Retour au menu lecture</button>
                </NavLink>
        </div>
        <div style={{height:"4em"}}></div>
      </div>
      )}
      {!loading&&(
        <Loader></Loader>
      )}
    </>
  );
}
import React, {useState ,useContext} from "react";
import { useEffect} from "react";
import {Context} from '../context/ContextProvider';
import './Page.css';
import Boxco from './../Components/Block/Boxco2';
import $ from "jquery";
import {useNavigate } from "react-router-dom";




export default function Connexion() {
    //const {connected, updateconnected} = useContext(Context);
    const {connected} = useContext(Context);
    const [isRegister0, setIsRegister0] = useState(false);
    const [coSuccess, setcoSuccess] = useState(false);
    const [regSuccess, setregSuccess] = useState(false);
    const [errorMessage, seterrorMessage] = useState(false);
    const navigate = useNavigate();





  const changemessageError = (result) => {
        /*
        console.log("result");
        console.log(result);
        console.log(JSON.stringify(result));
        console.log("......................");
        */
        if(result && result.success===false && result.message){
          //const success=result.success;
          const message=result.message;
          seterrorMessage(message);
          /*
          if(success===false){
              seterrorMessage(message);
          }
          */
      }
      else{
        seterrorMessage(false);
      }

  };

  useEffect(
    () => {
      $("#logo").addClass("exit");
   
      return () => {
        $("#logo").removeClass("exit");
      }

    },
    [],
  );


  useEffect(
    () => {

        if(connected){
            navigate("/", { connection: true });
        }

   
      return () => {
      }

    },
    [connected,navigate],
  );

  return (
    <>
      <section id="" className="bigbox apparition">
        <h1 style={{marginBottom:"1em"}} className="titlepage titleaccueil">Connexion / Inscription</h1>
    
        <Boxco regist={isRegister0} changeregister={setIsRegister0} coSuccess={coSuccess} setcoSuccess={setcoSuccess} regSuccess={regSuccess} setregSuccess={setregSuccess} errorMessage={errorMessage} seterrorMessage={seterrorMessage} changemessageError={changemessageError} ></Boxco>

        
      </section>
    </>
  );
}
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState , useContext } from "react";
import './Page.css';
import $ from "jquery";
import Audio from "../Components/Audioreader/Audio.js";
import {useNavigate ,NavLink } from "react-router-dom";
import ToggleSwitch from "../Components/ToggleSwitch/ToggleSwitch";
import Arrow from './../items/Image/arrow3.svg';
import Loader from "../Components/Loader/Loader.js";
import {Context} from '../context/ContextProvider';
import {getReglage } from "../actions/user.js";
import { useLocation } from 'react-router-dom';




export default function Basmalah2(props) {
  const {connected} = useContext(Context);
  const {surat,verse} = useParams();
  //const history = useHistory();
  const navigate = useNavigate();

  const [Basmalah, setBasmalah] = useState("");
  const [Français, setFrançais] = useState("");
  const [English, setEnglish] = useState("");
  const [AudioUrl, setAudio] = useState("");
  const [IdAyah, setIdAyah] = useState(0);
  const [SuratId, setSuratId] = useState(0);
  const [VerseId, setVerseId] = useState(0);
  const [SuratName, setSuratName] = useState("");
  const [SuratNameFrancais, setSuratNameFrancais] = useState("");
  /*
  const [nextSurat, setnextSurat]=useState(0)
  const [nextVerse, setnextVerse]=useState(0)*/
  const [previousSurat, setpreviousSurat]=useState(0)
  const [previousVerse, setpreviousVerse]=useState(0)
  const [nointerupt,setnointerupt]=useState(false)
  const [suratInfo, setSuratInfo] = useState({});
  const [Reglage,setReglage]=useState([]);


  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [goDemarre, setGoDemarre] = useState(false); 
  const [Lecture, setLecture] = useState(false);
  const [volume, setVolume] = useState(false);
  const [newVolume, setNewVolume] = useState(false);

  let location = useLocation();
  
  // Accéder à l'état passé
  let Continue = location.state?.Continue;
  let LectureC=location.state?.LectureC;
  let AudioC=location.state?.AudioC;


  useEffect(() => {
    if (Continue) {
      console.log(Continue);
      setnointerupt(Continue);

    }
    if(LectureC){
        setGoDemarre(true);
    }
  }, [Continue , LectureC, setnointerupt, setGoDemarre]);


  useEffect(() => {
    if (AudioC) {
      console.log(AudioC);
      setNewVolume(AudioC);
    }
  }, [AudioC]);


  useEffect(
    () => {
      if(connected){
        getReglage(setReglage);
      }
      return ()=>{

      }
    },
    [connected],
  );
  useEffect(
    () => {
      if(Object.keys(Reglage).length>=1){
        setLoading2(true);
      }
      else{
        setLoading2(false);
      }
      return ()=>{

      }
    },
    [Reglage],
  );


  const PVerse=()=>{
    if(Math.floor(IdAyah)<=6236 && Math.floor(IdAyah)>1){
        navigate('/verse/'+Math.floor(previousSurat).toString()+"/"+Math.floor(previousVerse).toString(), { state: { ContinueB: nointerupt, LectureB:Lecture , AudioB:volume } })

    }

  }
  const NVerse=()=>{
      if(Math.floor(IdAyah)<=6236 && Math.floor(IdAyah)>=1){
        navigate('/verse/'+Math.floor(SuratId).toString()+"/"+Math.floor(VerseId).toString(), { state: { ContinueB: nointerupt, LectureB:Lecture , AudioB:volume } })
      }
  }

  const Ecoutecontinue=()=>{

    var url = window.location.pathname;
    var segments = url.split('/').filter(Boolean); // Filtre les segments vides

    if (segments.length < 2) {
        console.error("Pas assez de segments trouvés.");
        return;
    }

    // Récupération des deux derniers nombres
    let verse = Math.floor(segments[segments.length - 1]);
    let surat = Math.floor(segments[segments.length - 2]);
    if(IdAyah<=6236 && IdAyah>=1){
        navigate('/verse/'+(surat).toString()+"/"+(verse).toString(), { state: { ContinueB: nointerupt , LectureB:Lecture , AudioB:volume } })
    }


  }



  
  const GetVerse = useCallback((suratId,verseId) => {
    //alert("okep");


    fetch('https://hafiz-quran.com/express/verse/'+Math.floor(suratId).toString()+'/'+Math.floor(verseId).toString(), {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },

    })
    .then((response) => response.json())
    .then((data) => {
      if(!data["Error"]){
        if(Math.floor(verseId)===1 && Math.floor(suratId)===1){
            navigate('/verse/'+(Math.floor(suratId)).toString()+'/'+(Math.floor(verseId)).toString(), { state: { ContinueB: nointerupt , LectureB:Lecture , AudioB:volume }})
        }
        else{
            setBasmalah("بِسْمِ اللَّهِ الرَّحْمَٰنِ الرَّحِيمِ ");
            setFrançais("Au nom d´Allah, le Tout Miséricordieux, le Très Miséricordieux.");
            setEnglish("In the Name of Allah, the Most Beneficent, the Most Merciful.");
            setAudio("https://hafiz-quran.com/express/ayah/001/001001.mp3");
        }
        setIdAyah(data["ID"])
        setSuratId(data["SuratID"]);
        setVerseId(data["VerseID"]);
        setSuratName(data["SuratName"]);
        setSuratNameFrancais(data["SuratNameFrancais"]);
        setLoading(true);



      }

    },

      (error) => {
        console.log(error);
        console.log("Pas de Connexion");
      }

    );
  },[setBasmalah,setFrançais,setEnglish,setAudio,setIdAyah,setSuratId,setVerseId,setSuratName,setSuratNameFrancais,setLoading,navigate,nointerupt,Lecture,volume]);

  /*
  const GetVerse2 = (verseId) => {
    //alert("okep");


    fetch('https://hafiz-quran.com/express/verse/'+Math.floor(verseId).toString(), {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },

    })
    .then((response) => response.json())
    .then((data) => {
      if(!data["Error"]){
        setnextSurat(data["SuratID"]);
        setnextVerse(data["VerseID"]);
      }

    },

      (error) => {
        console.log(error);
        console.log("Pas de Connexion");
      }

    );
  };
  */
  const GetVerse3 = (verseId) => {
    //alert("okep");


    fetch('https://hafiz-quran.com/express/verse/'+Math.floor(verseId).toString(), {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },

    })
    .then((response) => response.json())
    .then((data) => {
      if(!data["Error"]){
        setpreviousSurat(data["SuratID"]);
        setpreviousVerse(data["VerseID"]);
      }

    },

      (error) => {
        console.log(error);
        console.log("Pas de Connexion");
      }

    );
  };

  const changeInput1=()=>{
    if(Math.floor($("#inp1").val())&& Math.floor($("#inp1").val())>=1 && Math.floor($("#inp1").val())<=114){
      $("#nbverse").html(suratInfo[Math.floor($("#inp1").val())-1]["Nbreverset"]);
      $("#verse").attr("max",suratInfo[Math.floor($("#inp1").val())-1]["Nbreverset"]);
    }
    else{
      $("#nbverse").html(0);
      $("#verse").attr("max",1);
    }
  }

  const getSuratInfoApi=()=>{



    fetch('https://hafiz-quran.com/express/suratinfo', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
    .then((response) => response.json())
    .then((data) => {
      setSuratInfo(data);
    },

      (error) => {
        console.log("Pas de Connexion");
      }

    );
  }

  const goToVerse=()=>{
    let valuesurat=Math.floor($("#inp1").val());
    let valueverse=Math.floor($("#verse").val());
    let nubverse=suratInfo[Math.floor($("#inp1").val())-1]["Nbreverset"];
    console.log(valuesurat+"/"+valueverse+"/"+nubverse+"/");
    if(valuesurat<=114 && valuesurat>=1 && valueverse<=nubverse && valueverse>=1){
      navigate('/verse/'+Math.floor(valuesurat).toString()+"/"+Math.floor(valueverse).toString(), {})
    }
  }

  useEffect(
    () => {
      if(Math.floor(verse)>286 || Math.floor(verse)<1 || Math.floor(surat)>114 || Math.floor(surat)<1){
        //history.push("/error-id")
        navigate("/error-id", { replace: true })
      }
      else{
        GetVerse(surat,verse);
      }

      getSuratInfoApi();

      function handleKeyUp(event) {
        if(event.which === 37){
            let btnc1 = document.querySelector("#previousverse");
            if (btnc1) {
                btnc1.click();
            } else {
                console.log("Error");
            }
        }
        if(event.which === 39){
            let btnc0 = document.querySelector("#nextverse");
            if (btnc0) {
                btnc0.click();
            } else {
                console.log("Error");
            }
        }
      }

      $(window).on('keyup', handleKeyUp); // Attach the event listener
      return () => {
        $(window).off('keyup', handleKeyUp); // Cleanup: Remove the event listener
      };
    },
    [surat,verse,navigate,GetVerse],
  );
  /*
  useEffect(
    () => {
      if(Math.floor(verse)>286 || Math.floor(verse)<1 || Math.floor(surat)>114 || Math.floor(surat)<1){
        //history.push("/error-id")
        navigate("/error-id", { replace: true })
      }
      else{
        GetVerse(surat,verse);
      }
    },
    [surat,verse],
  );
    */


  useEffect(
    () => {
    /*
      if(Math.floor(IdAyah)<6236){ 
        GetVerse2(Math.floor(IdAyah)+1);
      }
    */
      if(Math.floor(IdAyah)>1){
        GetVerse3(Math.floor(IdAyah)-1);

      }

    },
    [IdAyah],
  );


  let newclass="cadreafterfixe";
  if(props.classrepair){
    newclass=newclass+" "+props.classrepair
  }
  
  
  let datal=""
  if(suratInfo[1]){
    for(let i=0; i<Object.keys(suratInfo).length; i++){
      datal=datal+"<option value="+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" >"+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Francais"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Arabe"]+"</option>";

    }
  }

  return (
    <>
      {( loading && (!connected || loading2 ))&&(
      <div className={newclass}>
        <div className="cadreverse apparitionq">
            {Math.floor(IdAyah)!==1 &&(
                <>
                    <div className="boxsurattitle centerco">

                        <h1 style={{marginBottom: "0.3em"}}>Surat Nº<span>{SuratId}</span></h1>
                        <h4 className="margin0"><span>{SuratName}</span> / <span>{SuratNameFrancais}</span></h4>
                    </div>

                </>
            )}
          <div style={{marginTop:"2em"}}></div>
          {(!connected ||Reglage.ArabicLecture)&&(<p className=" ayah ayahalone verse center talign-end">{Basmalah}</p>)}
          {(!connected ||Reglage.FrenchLecture)&&(<p className=" ayahfrench verse center talign-start">{Français}</p>)}
          {(!connected ||Reglage.EnglishLecture)&&(<p className=" ayahfrench verse center talign-start">{English}</p>)}
          <div style={{marginTop:"2em"}}></div>
          <Audio audio1={AudioUrl} dontStop={true} EndBool={nointerupt} fonctionEnd={Ecoutecontinue} demarrer={goDemarre} setLecture={setLecture} newVolume={newVolume} setVolume={setVolume} className="repairaudio"/>
          <div className="center">Audio en continue : <ToggleSwitch first={nointerupt} fonct={(result)=>{setnointerupt(result)}}></ToggleSwitch></div>
        </div>
        <div className="center">
                  <button id="previousverse" className={Math.floor(IdAyah)===1 ? "bt0 returngame pointer center" : " returngame pointer center"} /*className="returngame pointer center"*/ onClick={PVerse} ><img alt='verset précédent' className="smallarrow" src={Arrow}></img></button>
                  <button id="nextverse" className={Math.floor(IdAyah)===6236 ? "bt0 returngame pointer center" : " returngame pointer center"} onClick={NVerse} ><img alt='verset suivant' className="smallarrow2" src={Arrow}></img></button>
        </div>
        <div style={{height:"3em"}}></div>
        <div className="centerco manette exit">
              <div className="centrer talign">
                <input id="inp1" className="inp1 inputdefault" name="surat" placeholder="Surat" list="suratname" onInput={changeInput1}></input>
                  <datalist id="suratname">

                    <div dangerouslySetInnerHTML={{ __html: datal }} />

                  </datalist>
                  
                  <input type="number" name="verse" id="verse" className="inp3 inputdefault" min={1} max={1} placeholder="Verse" required=""></input>
                  

              </div>
              <p>Nombre de versets dans la sourate choisis : <span id="nbverse">0</span></p>
              <button id="btnsend" className="btn btn3" onClick={goToVerse} >Aller à ce verset</button>
        </div>
        <div className="centerco"> 
                <NavLink className={"linkclass"} to="/lecture/">
                    <button className="returngame pointer center" ><img alt='' className="imglink arrowreturn" src={Arrow}></img>Retour au menu lecture</button>
                </NavLink>
        </div>
        <div style={{height:"4em"}}></div>
      </div>
      )}
      {!loading&&(
        <Loader></Loader>
      )}
    </>
  );
}
import React, {useContext , useEffect, useCallback} from "react";
import {Context} from './../../context/ContextProvider';
import './../Page.css';
import {useNavigate ,NavLink } from "react-router-dom";
import SettingImg from "./../../items/Image/SettingImg.svg"
import Ranking from "./../../items/Image/ranking.svg"
import Resume from "./../../items/Image/resume.svg"
import NewGame from "./../../items/Image/newgame.svg"
import {checkcon } from "./../../actions/user.js";



export default function Home() {
  //const {connected, updateconnected} = useContext(Context);
  const {connected} = useContext(Context);

  const navigate = useNavigate();

  const goConnexion = useCallback((bool) => {
    if (!bool) {
        navigate("/connexion", { connection: true });
    }
}, [navigate]); 

  useEffect(
    () => {

        if(!connected){
            checkcon(goConnexion);
            //navigate("/connexion", { connection: true });
        }
        else if(connected){
        }

      return () => {
      }

    },
    [connected,goConnexion],
);


  return (
    <>
    <div className="centerco apparitionq">
        <NavLink className={"linkclass apparitionq"} to="/game/list-game">
            <button className="btn0 center" ><img alt='' className="imglink" src={Resume}></img>Reprendre une partie</button>
        </NavLink>

        <NavLink className={"linkclass apparitionq"}  to="/game/new-game">
            <button  className="btn0 center" ><img alt='' className="imglink" src={NewGame}></img>Nouvelle Partie</button>
        </NavLink>

        <NavLink className={"linkclass apparitionq"}  to="/game/classement">
            <button  className="btn0 center" ><img alt='' className="imglink" src={Ranking}></img>Classement</button>
        </NavLink>

        <NavLink className={"linkclass apparitionq"}  to="/game/reglage">
            <button  className="btn0 center" ><img alt='' className="imglink" src={SettingImg}></img>Réglages</button>
        </NavLink>
        
    </div>
    </>
  );
}
import React,{ useContext , useState, useEffect } from 'react';
import {Context} from './../context/ContextProvider';
import { checkco } from './../actions/user.js';
import Header from './Block/Header.js';
import Footer from './Block/Footer';
import Boxco from './Block/Boxco';
import { useSelector } from 'react-redux';





export default function Cadre(props) {
    //const {connected, updateconnected} = useContext(Context);
    const {updateconnected} = useContext(Context);
    const [isRegister0, setIsRegister0] = useState(false);
    const [coSuccess, setcoSuccess] = useState(false);
    const [regSuccess, setregSuccess] = useState(false);
    const [errorMessage, seterrorMessage] = useState(false);
    //const [onHeader, setonHeader] = useState(true);
    const onHeader=useSelector(state => state.onHeader);
    const onFooter=useSelector(state => state.onFooter);



    const changemessageError = (result) => {
        /*
        console.log("result");
        console.log(result);
        console.log(JSON.stringify(result));
        console.log("......................");
        */
        if(result && result.success===false && result.message){
            //const success=result.success;
            const message=result.message;
            seterrorMessage(message);
            /*
            if(success===false){
                seterrorMessage(message);
            }
            */
        }
        else{
            seterrorMessage(false);
        }


      };



    useEffect(
        () => {
            checkco(updateconnected);
        },
        [updateconnected],
    );

    useEffect(() => {
        const intervalID = setInterval(() => {
          checkco(updateconnected);
        }, 80000);  // 300000 millisecondes équivalent à 1 heure
      
        return () => {
          clearInterval(intervalID);  // Supprimer l'intervalle lorsque le composant est démonté
        };
      }, [updateconnected]);

    

    return (
        <>
            {onHeader.value&&(<Header changeregister={setIsRegister0} setcoSuccess={setcoSuccess} setregSuccess={setregSuccess} seterrorMessage={seterrorMessage} ></Header>)}
            <div className='cadrefixe'>
                {props.children}
            </div>
            {onFooter.value&&(<Footer></Footer>)}
            <div className='modalscreen modalsenter exit'></div>
            <div className='modalcenter modalbenter exit'>
                <Boxco regist={isRegister0} changeregister={setIsRegister0} coSuccess={coSuccess} setcoSuccess={setcoSuccess} regSuccess={regSuccess} setregSuccess={setregSuccess} errorMessage={errorMessage} seterrorMessage={seterrorMessage} changemessageError={changemessageError} ></Boxco>
            </div>
        </>
    )
}
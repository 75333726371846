import React from 'react';
import "./Pub.css";
import pubData from './pub.json';


function PubHorizontal({ pubNumber , Type , Exist }) {
  const handleClick = async () => {
    try {
      const response = await fetch(`https://hafiz-quran.com/express/pub/click/${pubNumber}`, {
        method: 'POST'
      });

      if (response.ok) {
        console.log("Click enregistré !");
      } else {
        const data = await response.json();
        console.error("Erreur lors de l'enregistrement du click :", data.message);
      }
    } catch (error) {
      console.error("Erreur réseau :", error);
    }
  };

  // Sélection d'une image au hasard
  const images = pubData[pubNumber].images;
  const randomImage = images[Math.floor(Math.random() * images.length)];
  const linkUrl = pubData[pubNumber].link;
  

  return (
    <>
    {Exist && (
    <div className={Type? "apparitionq pub-horizontal-container"+Type :"apparitionq pub-horizontal-container"} onClick={handleClick}>
        <a href={linkUrl} target="_blank"  rel="noopener noreferrer">
          <img src={randomImage} alt={`Publicité horizontale ${pubNumber}`} className="pub-horizontal-image" />
        </a>
    </div>
    )}
    </>
  );
}

export default PubHorizontal;







import React, {useContext} from "react";
import { useEffect, useState} from "react";
import {Context} from '../../context/ContextProvider';
import './Header.css';
import { ReactComponent as LogoHead } from './../../items/Image/Bookplus.svg';
import { ReactComponent as HamburgerMenu } from './../../items/Image/hamburgermenu.svg';
import { ReactComponent as Account } from './../../items/Image/account.svg';
import { Link } from "react-router-dom";
import { deco} from './../../actions/user.js';
import { useNavigate } from "react-router-dom";


import $ from "jquery";





export default function Header(props) {
  const {connected, updateconnected} = useContext(Context);
  const [onMenu, setonMenu] = useState(false);
  const navigate = useNavigate();


  useEffect(
    () => {

      $( ".logohead" ).mouseover(function() {
        $(".cls-1book").attr("class","cls-1book logopathenter2")
      });
      $( ".logohead" ).mouseleave(function() {
        $(".cls-1book").attr("class","cls-1book")
      });

      $( ".hammenu" ).mouseover(function() {
        $(".cls-2").attr("class","cls-2 hamenter")
      });
      $( ".hammenu" ).mouseleave(function() {
        $(".cls-2").attr("class","cls-2")
      });

      $(".modalcenter").click(function(event){

        if($(event.target).hasClass("pastouche")){
          return;}
        $(".modalscreen").addClass("exit");
        $(".modalcenter").addClass("exit");
        $(".boxco").addClass("exit");

      })      

      $( ".account" ).mouseover(function() {
        $(".cls-5").attr("class","cls-5 hamenter")
      });
        $( ".account" ).mouseleave(function() {
          $(".cls-5").attr("class","cls-5")
      });

      $(".headplus").addClass("exit");
      $("#spaceheader").attr("class","headerspace")

    },
    [],
  );
  useEffect(
    () => {     

      $( ".account" ).mouseover(function() {
        $(".cls-5").attr("class","cls-5 hamenter")
      });
        $( ".account" ).mouseleave(function() {
          $(".cls-5").attr("class","cls-5")
      });

    },
    [connected],
  );


  const handleDeco = (e) => {
    e.preventDefault();
    deco(updateconnected);
    props.seterrorMessage(false);
  };
  const goGame = (e) => {
    navigate("/game", { connection: true });
  };

  const revealCo = (e) => {
    e.preventDefault();
    props.changeregister(false);
    props.setcoSuccess(false);
    props.setregSuccess(false);
    props.seterrorMessage(false);
    $(".modalscreen").removeClass("exit");
    $(".modalcenter").removeClass("exit");
    $(".boxco").removeClass("exit");
    
  };
  const revealReg = (e) => {
    e.preventDefault();
    props.changeregister(true);
    props.setcoSuccess(false);
    props.setregSuccess(false);
    props.seterrorMessage(false);
    $(".modalscreen").removeClass("exit");
    $(".modalcenter").removeClass("exit");
    $(".boxco").removeClass("exit");
    
  };

  const rMenu = (e) => {
    e.preventDefault();
    /*
    if(onMenu){
      setTimeout(function(){
        $(".headplus").addClass("exit");

      },450)
    }
    */
    setonMenu(!onMenu);
  };

  return (
    <>
      <header className="head apparition">
        <Link className="pointer logolink" to="/"><LogoHead className="logohead"></LogoHead></Link>
        <nav className="a768f navhead">
          <Link className="linkhead pointer" to="/">Accueil</Link>
          <Link className="linkhead pointer" to="/a-propos">À Propos</Link>
          <Link className="linkhead pointer" to="/lecture">Lecture</Link>
          <Link className="linkhead pointer" to="/contact">Contact</Link>
          {connected&&(<Link className="linkhead pointer account exit" to="/mon-compte"><Account className="iconaccount"></Account>Mon Compte</Link>)}
        </nav>
        {!connected&&(

          <div className="head2button">
            <button onClick={revealCo} className="btn btnheader btnheader1 pointer">Se Connecter</button>
            <button onClick={revealReg} className="btn btnheader btnheader2 pointer">Créer un compte</button>
          </div>

        )}

        {connected&&(

        <div className="head2button2">
          <button onClick={goGame} className="btn btnheader0 btnheader3 pointer">Lancer le jeu</button>
          <button onClick={handleDeco} className="btn btnheader0 btnheader4 pointer">Se déconnecter</button>
        </div>

        )}


        <div className="hammenu pointer d768" onClick={rMenu}><HamburgerMenu className="hamlogo"></HamburgerMenu><span>Menu</span></div>

      </header>
      <div className={onMenu?"headplus menuopen":"headplus menuclose"}>
        <nav className="d768 navhead2">
          <Link className="linkhead2 pointer" to="/">Accueil</Link>
          <Link className="linkhead2 pointer" to="/a-propos">À Propos</Link>
          <Link className="linkhead2 pointer" to="/lecture">Lecture</Link>
          <Link className="linkhead2 pointer" to="/contact">Contact</Link>
          {connected&&(<Link className="linkhead2 pointer account exit" to="/mon-compte"><Account className="iconaccount"></Account>Mon Compte</Link>)}
        </nav>
      </div>
      {/*<div id="spaceheader" className={onMenu?"headerspace2 menuopen2":"headerspace menuclose2"}></div>*/}
      {<div id="spaceheader" className="headerspace "></div>}

    </>
  );
}
import React from "react";
import './Page.css';
import mosque from './../items/Image/illustrations/mushafreader3.webp';
import young from './../items/Image/illustrations/plume.webp';
import {useNavigate } from "react-router-dom";

import Logo from "./../items/Image/Bookplus.svg"
import Help from "../Components/Help";





export default function Accueil() {
  const navigate = useNavigate();


  const goGame = (e) => {
    navigate("/game", { connection: true });
  };

  return (
    <>
      <section id="accueil" className="bigbox apparition">
        <div id="logo" className="cadrelogo centerco">
          <div className="boxlogo centerco">        
              <img alt='Logo' src={Logo} className="imglogo apparitionq"></img>
              <h1 className="titrelogo">HÂfIDH</h1>
          </div>
          <h3 className="sublogo">Allez vous le compléter ?</h3>
        </div>
        <div style={{height:"3em"}}></div>
        <div className="boxparent0 a1024">
          <div className="parent ligne1">
            <div className="div1 tobottom t40">
              <div className="presentation-section">
                  <h1>Bienvenue sur HAFIDH : Le Défi du Coran ! 🌙</h1>
                  <p>Que vous ayez déjà mémorisé des parties du Coran ou que vous soyez un débutant en quête de connaissances, HAFIDH vous accueille à bras ouverts !</p>
                  <ul>
                      <li><strong>Pour Tous les Niveaux</strong> : Des modes de jeu adaptés pour tous, du novice à l'expert. Chacun trouvera son défi à la mesure de ses connaissances.</li>
                      <li><strong>Jeu Multilingue</strong> : Que vous parliez arabe, anglais ou une autre langue, plongez dans l'univers sacré du Coran.</li>
                      <li><strong>Modes Variés</strong> : Identifiez la sourate mystère, retrouvez le verset manquant et plus encore. Il y a toujours quelque chose de nouveau à découvrir.</li>
                      <li><strong>Chronométré</strong> : Testez votre rapidité et votre mémoire contre la montre, quel que soit votre niveau.</li>
                      <li><strong>Écoutez le Coran</strong> : Que vous souhaitiez mémoriser ou simplement écouter, notre fonctionnalité audio est là pour vous accompagner.</li>
                      <li><strong>Classement Mondial</strong> : Des points, des défis, une compétition amicale. Où vous situerez-vous ?</li>
                  </ul>
              </div>
            </div>
            <div className="div2 tocenter">
              <div className="boximgmosque centerco">
                <img alt='' className="imgmosque2 apparitionq" src={mosque}></img> 
              </div>
            </div>
            <div className="div3 toright t40"> 
              <img alt='' className="imgyoung apparitionq imgplume" src={young}></img>
            </div>
            <div className="div4 toup2 t40">
                <div className="presentation-section">
                    <h1>Embarquez dans cette quête spirituelle</h1>
                    <p>Embarquez dans cette quête spirituelle où l'apprentissage se mêle au divertissement. Que vous soyez un hafidh chevronné ou que vous fassiez vos premiers pas, HAFIDH est votre allié. Relevez le défi, progressez à votre rythme et célébrez chaque verset appris.</p>
                    <p>Alors, prêt à éclairer votre chemin avec le Coran ? Lancez-vous et que chacun trouve sa voie !</p>
                </div>

              <button  onClick={goGame} className="btn btngame btng pointer">Lancer le jeu</button>
            </div>
          </div>
        </div>
        <div className="boxparent2 centerco d1024">
          <div className="lignetype1">
            <img alt='' className="imgmosque apparitionq" src={mosque}></img>
            <div className="accueiltext">
              <div className="presentation-section">
                    <h1>Bienvenue sur HAFIDH : Le Défi du Coran ! 🌙</h1>
                    <p>Que vous ayez déjà mémorisé des parties du Coran ou que vous soyez un débutant en quête de connaissances, HAFIDH vous accueille à bras ouverts !</p>
                    <ul>
                        <li><strong>Pour Tous les Niveaux</strong> : Des modes de jeu adaptés pour tous, du novice à l'expert. Chacun trouvera son défi à la mesure de ses connaissances.</li>
                        <li><strong>Jeu Multilingue</strong> : Que vous parliez arabe, anglais ou une autre langue, plongez dans l'univers sacré du Coran.</li>
                        <li><strong>Modes Variés</strong> : Identifiez la sourate mystère, retrouvez le verset manquant et plus encore. Il y a toujours quelque chose de nouveau à découvrir.</li>
                        <li><strong>Chronométré</strong> : Testez votre rapidité et votre mémoire contre la montre, quel que soit votre niveau.</li>
                        <li><strong>Écoutez le Coran</strong> : Que vous souhaitiez mémoriser ou simplement écouter, notre fonctionnalité audio est là pour vous accompagner.</li>
                        <li><strong>Classement Mondial</strong> : Des points, des défis, une compétition amicale. Où vous situerez-vous ?</li>
                    </ul>
                </div>
              <div className="a1024f">
                <img alt='' className="imgyoung apparitionq" src={young}></img>
                <div className="centerco">
                    <div className="presentation-section textaccueil2">
                        <h1>Embarquez dans cette quête spirituelle</h1>
                        <p>Embarquez dans cette quête spirituelle où l'apprentissage se mêle au divertissement. Que vous soyez un hafidh chevronné ou que vous fassiez vos premiers pas, HAFIDH est votre allié. Relevez le défi, progressez à votre rythme et célébrez chaque verset appris.</p>
                        <p>Alors, prêt à éclairer votre chemin avec le Coran ? Lancez-vous et que chacun trouve sa voie !</p>
                    </div>
                  <button  onClick={goGame} className="btn btngame btng pointer">Lancer le jeu</button>

                </div>
              </div>

            </div>

          </div>
          <div className="lignetype2">
            <img alt='' className="imgyoung apparitionq" src={young}></img>
            <div className="accueiltext centerco">
                <div className="presentation-section">
                    <h1>Embarquez dans cette quête spirituelle</h1>
                    <p>Embarquez dans cette quête spirituelle où l'apprentissage se mêle au divertissement. Que vous soyez un hafidh chevronné ou que vous fassiez vos premiers pas, HAFIDH est votre allié. Relevez le défi, progressez à votre rythme et célébrez chaque verset appris.</p>
                    <p>Alors, prêt à éclairer votre chemin avec le Coran ? Lancez-vous et que chacun trouve sa voie !</p>
                </div>
              <button  onClick={goGame} className="btn btngame btng pointer">Lancer le jeu</button>
            </div>

          </div>
        </div>
      </section>
        <div style={{margin:"-5em"}}></div>
        <Help></Help>
    </>
  );
}
import React from "react";
import './../Pages/Page.css';
import { NavLink} from "react-router-dom";
import Donate from "./../items/Image/donate.svg"
import PubHorizontal from "../Components/Pub/PubHorizontal";





export default function Help() {

  return (
    <>
                  <div id="theposition" style={{height:"4em"}}></div>
                  <hr style={{width:"20em",maxWidth:"98vw"}}/>
                  <div id="help" className="helpbox centerco">
                    <div className="help centerco apparitionq"> 
                      <p className="talign">Vous pouvez nous soutenir pour poursuivre et perfectionner ce projet, ainsi que pour en initier de nouveaux.</p>
                      <NavLink className={"linkclass apparitionq"} target="_blank" rel="noopener noreferrer"  to="https://fr.tipeee.com/ummahtech/">
                          <button  className="btn0 center">Donation <div style={{width:"0.5em"}}></div><img alt='' className="imglink" src={Donate}></img></button>
                      </NavLink>

                    </div>
                  </div>
                  <div  className="helpbox centerco">
                    <div className="pubh centerco apparitionq"> 
                        <PubHorizontal pubNumber={3} Type={""} Exist={false}/>
                        <PubHorizontal pubNumber={4} Type={""} Exist={false}/>
                    </div>
                  </div>
                  <div style={{height:"3em"}}></div>
    </>
  );
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom"
import {store} from "./store/store.js"
import {Provider} from "react-redux"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </>


)

reportWebVitals();

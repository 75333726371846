import React , {useContext,useCallback} from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link , NavLink ,useNavigate } from "react-router-dom";
import $ from "jquery";
import Audio from "../../Components/Audioreader/Audio.js";
import Time from "./../../Components/Timer/TimerCircle.js";
import {isUserinGame , getGameInfo , sendAResp ,sendARespSuratO , getNewVerse , Timeleft , checkcon , whichVersion} from "./../../actions/user.js";
import Arrow from './../../items/Image/arrow3.svg';
import ImgLoose from './../../items/Image/illustrations/loosegame.webp';
import ImgVictory from './../../items/Image/illustrations/victory.webp';
import {Context} from './../../context/ContextProvider';


export default function Game(props) {
  

  const {id} = useParams();
  const [gameInfo, setgameInfo] = useState({});
  const [suratInfo, setSuratInfo] = useState({});
  const [Playing, setPlaying] = useState(false);
  const [Ayah, otherAyah] = useState(false);
  const [Français, otherFrançais] = useState(false);
  const [English, otherEnglish] = useState(false);
  const [Score, setScore] = useState(0);
  const [BestScore, setBestScore] = useState(0);
  const [AudioUrl, setAudio] = useState("");
  const [OldVerse, setOldVerse] = useState(false);
  const [OldVerseID, setOldVerseID] = useState(0);
  const [URLverif, setURLverif] = useState("");
  const [OldResult, setOldResult] = useState();
  const [Ajout, setAjout] = useState(0);
  const [Timel, setTimel] = useState(0);

  const [SuratOldVerse,setSuratOldVerse]=useState(0);
  const [VerseOldVerse,setVerseOldVerse]=useState(0);
  const [SuratName,setSuratName]=useState("");
  const [SuratNameFr,setSuratNameFr]=useState("");


  const [CheckGame, setCheckGame] = useState(false);
  const [CheckVersion, setCheckVersion] = useState(false);
  const [DataType, setDataType] = useState(false);


  //const {connected, updateconnected} = useContext(Context);
  const {connected} = useContext(Context);
  const navigate = useNavigate();


const goConnexion = useCallback((bool) => {
    if (!bool) {
        navigate("/connexion", { connection: true });
    }
}, [navigate]); 

  useEffect(
    () => {

        if(!connected){
            checkcon(goConnexion);
            //navigate("/connexion", { connection: true });
        }
        else if(connected){
        }

      return () => {
      }

    },
    [connected,goConnexion],
);



  const changePlaying = () => {
    setPlaying(!Playing);
    
  };

  const SendResp = () => {
    let valuesurat=$("#inp1").val();
    let valueverse=$("#verse").val();
    sendAResp(Math.floor(id),Math.floor(valuesurat),Math.floor(valueverse),(data)=>{
      setScore(data["Score"]);
      setBestScore(data["BestScore"]);
      setPlaying(false);
      setOldVerseID(data["VerseID"]);
      let urlverif="./../../../verse/"+Math.floor(data["VerseID"]).toString();
      setURLverif(urlverif);
      setOldResult(data["ResultTest"])
      setAjout(data["Ajout"])
      setOldVerse(true);
    
    })
  };
  const SendRespSuratO = () => {
    let valuesurat=$("#inp1").val();
    sendARespSuratO(Math.floor(id),Math.floor(valuesurat),(data)=>{
      setScore(data["Score"]);
      setBestScore(data["BestScore"]);
      setPlaying(false);
      setOldVerseID(data["VerseID"]);
      let urlverif="./../../../verse/"+Math.floor(data["VerseID"]).toString();
      setURLverif(urlverif);
      setOldResult(data["ResultTest"])
      setAjout(data["Ajout"])
      setOldVerse(true);
    
    })
  };

  const GetOldVerse =  (verseId) => {
    //alert("okep");


    fetch('https://hafiz-quran.com/express/verse/'+Math.floor(verseId).toString(), {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },

    })
    .then((response) => response.json())
    .then((data) => {
      if(!data["Error"]){
        setSuratOldVerse(data["SuratID"]);
        setVerseOldVerse(data["VerseID"]);
        setSuratName(data["SuratName"]);
        setSuratNameFr(data["SuratNameFrancais"]); 
      }
      else{
        setSuratOldVerse(0);
        setVerseOldVerse(0);
        setSuratName("");
        setSuratNameFr(""); 
      }

    },

      (error) => {
        console.log(error);
        console.log("Pas de Connexion");
      }

    );
  };

  useEffect(
    () => {
  
      GetOldVerse(OldVerseID);
  
      return () => {
      }
  
    },
    [OldVerseID],
  );

  /*
  function isNumber(str) {
    if (str.trim() === '') {
      return false;
    }
  
    return !isNaN(str);
  }
  */

  const changeInput1=()=>{
    if(Math.floor($("#inp1").val())&& Math.floor($("#inp1").val())>=1 && Math.floor($("#inp1").val())<=114){
      $("#nbverse").html(suratInfo[Math.floor($("#inp1").val())-1]["Nbreverset"]);
      $("#verse").attr("max",suratInfo[Math.floor($("#inp1").val())-1]["Nbreverset"]);
    }
    else{
      $("#nbverse").html(0);
      $("#verse").attr("max",1);
    }
  }

  const SendLoose=()=>{
    if(Math.floor($("#inp1").val())&& Math.floor($("#inp1").val())>=1 && Math.floor($("#inp1").val())<=114 && Math.floor($("#verse").val())&& Math.floor($("#verse").val())>=1){
      let btnc = document.querySelector("#btnsend");
                      
      if (btnc) {
      btnc.click();
      }
      else {
      console.log("Error");
      }
    }
    else{
      $("#inp1").val(115);
      $("#verse").val(115)
      let btnc = document.querySelector("#btnsend");
                      
      if (btnc) {
      btnc.click();
      }
      else {
      console.log("Error");
      }
    }
  }
  const GetTime = useCallback(() => {
    Timeleft(Math.floor(id), (result) => {
      setTimel(result);
    });
  }, [id]); 


  const NewVerse = () => {
    //alert("okep");

    //const data = { UserId: 1,Game:id};

    getNewVerse(Math.floor(id),(data)=>{
      let NewInfo=gameInfo;
      if(!data["Error"]){
        NewInfo.Ayah=data.Ayah;
        NewInfo.Français=data.Français;
        NewInfo.English=data.English;
        NewInfo.AudioUrl=data.AudioUrl;
        otherAyah(data["Ayah"]);
        otherFrançais(data["Français"]);
        otherEnglish(data["English"]);
        setAudio(data.AudioUrl);
        //$("#audiopsource").attr("src","data.AudioUrl");
      }
      setgameInfo(NewInfo);
    })

    changePlaying();
  };

  const getTypeInfoApi=(Type,id)=>{

    if(Type!=="Juz"&&Type!=="Hizb"&&Type!=="Rub"){
      return false;
    }
    const urlfet='https://hafiz-quran.com/express/'+Type.toString().toLowerCase()+'-info/'+id.toString();
    fetch(urlfet, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
    .then((response) => response.json())
    .then((data) => {
      setDataType(data);
    },

      (error) => {
        console.log("Pas de Connexion");
      }

    );
  };
  
  const getGameApi = useCallback(() => {
    if (id && Number.isInteger(Math.floor(id))) {
      getGameInfo(id, (data) => {
        setgameInfo(data);
        setPlaying(data["Playing"]);
        otherAyah(data["Ayah"]);
        otherFrançais(data["Français"]);
        otherEnglish(data["English"]);
        setScore(data["Score"]);
        setBestScore(data["BestScore"]);
        setAudio(data.AudioUrl);
        getTypeInfoApi(data["Type"],data["Typenumber"]);
      });
      GetTime();
    }
  }, [id, GetTime]);

  const getSuratInfoApi=()=>{



    fetch('https://hafiz-quran.com/express/suratinfo', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
    .then((response) => response.json())
    .then((data) => {
      setSuratInfo(data);
    },

      (error) => {
        console.log("Pas de Connexion");
      }

    );
  };


  useEffect(() => {

    isUserinGame(Math.floor(id),(result)=>{
      setCheckGame(result);
    })
    whichVersion(Math.floor(id),"Classique",(result)=>{
      setCheckVersion(result);
    })
    return () => {
    }


  }, [id]);

  useEffect(() => {
    if(Playing){
      //getGameApi();
      //otherAyah("Test");
    }
    let temp=setTimeout(() => {
      /*
      if($("#help").hasClass("exit")){
        window.scrollTo(0, document.body.scrollHeight);
      }
      else{
          console.log("here");
          var position = $("#theposition").offset().top;
          var height=$("#help").outerHeight();
          console.log(document.body.scrollHeight);
          console.log(document.body.scrollHeight-height-$("#theposition").outerHeight());
          window.scrollTo(0, document.body.scrollHeight-height-$("#theposition").outerHeight());
      }*/
      /*
      if ($("#help").hasClass("exit")) {
        $("html, body").animate({ scrollTop: $(document).height() }, 1000);
      } else {
          var logoPosition = $("#logo").offset().top + $("#logo").height();
          $("html, body").animate({ scrollTop: logoPosition }, 1000);
      }*/
      if(Playing){
      var logoPosition = $("#logo").offset().top + $("#logo").height();
      window.scrollTo(0, logoPosition);
      }
    }, 100); 
    GetTime();
    return () => {
      clearTimeout(temp);
      window.scrollTo(0, 0);
    }


  }, [Playing , GetTime]);

  useEffect(() => {


    var cssId = 'myCss'; 
            
    if (!document.getElementById(cssId))
    {
        //var head  = document.getElementsByTagName('head')[0];
        var link  = document.createElement('link');
        link.id   = cssId;
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.media = 'all';

    }
    /*
    if(navigator.userAgent.indexOf("Firefox")>-1){
        //alert("Firefox")
        
        if (!document.getElementById(cssId))
        {
            link.href = "./../css/cmozila.css";
            head.appendChild(link);
        }
    }
    else if(navigator.userAgent.indexOf("Chrome")>-1){
        //alert("Chrome")
        if (!document.getElementById(cssId))
        {
            link.href = "./../css/cchrome.css";
            head.appendChild(link);
        }
        
    }
    */


    
    getGameApi();
    getSuratInfoApi();


    if($(window).outerHeight()-$("#game").outerHeight()<=360){
        $("#cadre").addClass("centercostart");
        $("#cadre").addClass("cadrepl");
        $("#cadre").removeClass("centerco");
        //alert($(window).outerHeight()-$("#game").outerHeight())
    }
    else{
        $("#cadre").addClass("centerco");
        $("#cadre").removeClass("centercostart");
        $("#cadre").removeClass("cadrepl");
        //alert($(window).outerHeight()-$("#game").outerHeight())
  
    }
    
    

    $(window).resize(function(){
      if($(window).outerHeight()-$("#game").outerHeight()>=360){
        $("#cadre").addClass("centerco");
        $("#cadre").removeClass("centercostart");
        $("#cadre").removeClass("cadrepl");
      }
      else{
        $("#cadre").addClass("centercostart");
        $("#cadre").addClass("cadrepl");
        $("#cadre").removeClass("centerco");
      }
    })


    $(window).keyup(function(event){
      if(event.which===13){
          let btnc = document.querySelector("#btnsend");
                      
          if (btnc) {
          btnc.click();
          }
          else {
          console.log("Error");
          }
  
  
      }
      if(event.which===13){
        let btnc = document.querySelector("#btnnverse");
                    
        if (btnc) {
        btnc.click();
        }
        else {
        console.log("Error");
        }


    }
  
  })


  return () => {

    $(window).unbind();

  }


  }, [getGameApi]);

useEffect(
    () => {
        $("#linkaccueil").removeClass("exit");
        $(".linkaccueil").hover(function(){
            $("#fleche").css( "fill", "#83BCFF");
        });
        
        $(".linkaccueil").mouseover(function(){
            $("#fleche").css( "fill", "#2B59C3");
        });
    
        return () => {
          $("#linkaccueil").addClass("exit");
          $('.linkaccueil').unbind();
        };
    },
    [],
);


  let playfalse="";
  let playtrue="";

  if(!gameInfo["Error"]){
 
    if(gameInfo["Type"]==="All"){
      playfalse=playfalse+'<div class="centrer talign">Versets choisi : sur tout le Coran</div>';
      //playtrue=playtrue+'<div class="firstblock">'+'<div class="centrer talign">'+'Versets choisi : sur tout le Coran'+'</div>';
    }
    if(gameInfo["Type"]==="Juz"){
      playfalse=playfalse+'<div class="centrer talign">Versets choisi : dans le Juz '+gameInfo["Typenumber"]+'</div>';
      playtrue=playtrue+'<div class="centrer talign"> Versets choisi : dans le Juz '+gameInfo["Typenumber"]+'</div>';
    }
    if(gameInfo["Type"]==="Hizb"){
      playfalse=playfalse+'<div class="centrer talign">Versets choisi : dans le Hizb '+gameInfo["Typenumber"]+'</div>';
      playtrue=playtrue+'<div class="centrer talign">Versets choisi : dans le Hizb '+gameInfo["Typenumber"]+'</div>';
    }
    if(gameInfo["Type"]==="Rub"){
      playfalse=playfalse+'<div class="centrer talign">Versets choisi : dans le Rub '+gameInfo["Typenumber"]+'</div>';
      playtrue=playtrue+'<div class="centrer talign">Versets choisi : dans le Rub '+gameInfo["Typenumber"]+'</div>';
    }
    if(gameInfo["Type"]==="Surat"){
      playfalse=playfalse+'<div class="centrer talign">Versets choisi : dans la Surat '+gameInfo["Typenumber"]+'</div>';
      playtrue=playtrue+'<div class="centrer talign">Versets choisi : dans la Surat '+gameInfo["Typenumber"]+'</div>';
    }
    //playfalse=playfalse+'<div class=" centrer talign">'+'Meilleur Score : '+gameInfo["BestScore"]+'</div>';
    //playfalse=playfalse+'<div class=" centrer talign">'+'Score Actuel : '+gameInfo["Score"]+'</div>';
    //playfalse=playfalse+'<div class="centrer">'+'<button class="btn">Verset suivant</button>'+'</div>'
    //playfalse=playfalse+'<div>'+'Dernière partie : '+gameInfo["LastTime"]+'</div>';
    //let date= new Date();
    //playfalse=playfalse+'<div>'+'Date : '+date+'</div>';

    //playtrue=playtrue+'<div class=" centrer talign">'+'Meilleur Score : '+gameInfo["BestScore"]+'</div>';
    //playtrue=playtrue+'<div class=" centrer talign">'+'Score Actuel : '+gameInfo["Score"]+'</div>'+'</div>';
    if(Ayah){
      playtrue=playtrue+'<h2>Ayah :</h2><p id="ayah" class=" ayah verse centrer talign-end">'+Ayah+'</p>';
    }
    if(Français){
      playtrue=playtrue+'<h2>Français :</h2><p class=" verse centrer talign-start">'+Français+'</p>';
    }
    if(English){
      playtrue=playtrue+'<h2>English :</h2><p class=" verse centrer talign-start">'+English+'</p>';
    }
    //playtrue=playtrue+'<div class="centrer talign">'+'<input type="text" name="surat" id="surat" class=" inp1 inputdefault" placeholder="Surat" required=""></input>'+'<input type="number" name="verse" id="verse" class="inp2 inputdefault" placeholder="Verse" required=""></input>'+'</div>'


  }
  let datal=""
  if(DataType){
    if(suratInfo[1]){
      for(let i=(Math.floor(DataType.FirstSurat)-1); i<(Math.floor(DataType.LastSurat)); i++){
        datal=datal+"<option value="+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" >"+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Francais"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Arabe"]+"</option>";
  
      }
    }
  }
  else{
    if(suratInfo[1]){
      for(let i=0; i<Object.keys(suratInfo).length; i++){
        datal=datal+"<option value="+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" >"+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Francais"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Arabe"]+"</option>";
  
      }
    }
  }


  if(Playing){
    if($(window).outerHeight()-$("#game").outerHeight()<=860){
      $("#cadre").addClass("centercostart");
      $("#cadre").addClass("cadrepl");
      $("#cadre").removeClass("centerco");
      //alert($(window).outerHeight()-$("#game").outerHeight())


    }
    else{
      $("#cadre").addClass("centerco");
      $("#cadre").removeClass("centercostart");
      $("#cadre").removeClass("cadrepl");
      //alert($(window).outerHeight()-$("#game").outerHeight())



    }
  }
  else{
    if($(window).outerHeight()-$("#game").outerHeight()<=165){
      $("#cadre").addClass("centercostart");
      $("#cadre").addClass("cadrepl");
      $("#cadre").removeClass("centerco");
      //alert($(window).outerHeight()-$("#game").outerHeight())

    }
    else{
      $("#cadre").addClass("centerco");
      $("#cadre").removeClass("centercostart");
      $("#cadre").removeClass("cadrepl");
      //alert($(window).outerHeight()-$("#game").outerHeight())




    }


  }

  return (
    (CheckVersion && CheckGame) ? (
      <>
        <div id="game" className="centerco talign game apparitionq">
          
          {(Playing && !gameInfo["Error"]) && (
            <h2 className="titlescore">Score <span>{Score}</span>/<span>{BestScore}</span></h2>
          )}

          {(OldVerse && !Playing && OldVerseID && VerseOldVerse && SuratOldVerse && SuratName && SuratNameFr) && (
              <div className="boxsurattitle centerco">
                <h1 style={{marginBottom: "0.3em"}}>Ayah Nº<span>{OldVerseID}</span></h1>
                <h4 className="margin0">Surat Nº<span>{SuratOldVerse}</span> <span>{SuratName}</span> / <span>{SuratNameFr}</span>{<br />} Verse Nº<span>{VerseOldVerse}</span></h4>
              </div>
          )}

          {(OldVerse && OldResult && !Playing && OldVerseID && URLverif) && (
            <>
              Vous avez gagné {Ajout} Points !!!
              <div className="boximg">
                <img alt='VICTOIRE' style={{width:"20em"}} src={ImgVictory}></img>
              </div>
              <Link className="link linkwin" target="_blank" rel="noopener noreferrer" to={URLverif}>Voir le verset</Link>
            </>
          )}



          {(OldVerse && !OldResult && !Playing && OldVerseID && URLverif) && (
            <>
              Vous avez perdus !!!
              <div className="boximg">
                <img alt='PERDU' style={{width:"20em"}} src={ImgLoose}></img>
              </div>
              <Link className="link linkloose" target="_blank" rel="noopener noreferrer" to={URLverif}>Voir le verset</Link>
            </>
          )}

          {(!Playing && !gameInfo["Error"]) && (
            <>
              <h1>Game Nº<span>{id}</span></h1>
              <div className=" center talign">Score Actuel : {Score}</div>
              <div className=" center talign">Meilleur Score : {BestScore}</div>

            </>
          )}
          {/*(Playing) && (
            <div>Testtest</div>
          )*/}
          {(Playing && Timel && !gameInfo["Error"]) && (
            <Time seconds={Timel} onComplete={SendLoose}/>
          )}
          {(Playing && !gameInfo["Error"] && AudioUrl) && (
            <Audio audio1={AudioUrl} classp="audioinplay"/>
          )}
          {Playing ? <div dangerouslySetInnerHTML={{ __html: playtrue }} /> : <div dangerouslySetInnerHTML={{ __html: playfalse }} />}
          {(!Playing && !gameInfo["Error"]) && (
            <>
              <button id="btnnverse" className="btn btn3" onClick={NewVerse} >Verset suivant</button>
              <div className="centerco"> 
                <NavLink className={"linkclass"} to="/game/">
                    <button className="returngame pointer center" ><img alt='' className="imglink arrowreturn" src={Arrow}></img>Retour au menu</button>
                </NavLink>
              </div>
            </>
          )}
          {(Playing && !gameInfo["Error"]) && (
            <div className="centerco manetteplay">
              <div className="centrer talign">
                <input id="inp1" className="inp1 inputdefault" name="surat" placeholder="Surat" list="suratname" onInput={changeInput1}></input>
                  <datalist id="suratname">

                    <div dangerouslySetInnerHTML={{ __html: datal }} />

                  </datalist>
                  {(!(CheckVersion==="SuratO"))&&(
                    <input type="number" name="verse" id="verse" className="inp3 inputdefault" min={1} max={1} placeholder="Verse" required=""></input>
                  )}
                  

              </div>
              <p>Nombre de versets dans la sourate choisis : <span id="nbverse">0</span></p>
              {(CheckVersion==="Classique")&&(
                <button id="btnsend" className="btn btn3" onClick={SendResp} >Envoyez ma réponse</button>
              )}
              {(CheckVersion==="SuratO")&&(
                <button id="btnsend" className="btn btn3" onClick={SendRespSuratO} >Envoyez ma réponse</button>
              )}
            </div>
          )}
          {(gameInfo["Error"]) && (
            <div>{gameInfo["Error"]}</div>
          )}
          


        </div>
      </>
    ):(
      <div className="centerco apparitionq"> 
        <div className="center talign errorgame">Vous n'êtes pas sur la bonne page</div>
        <NavLink className={"linkclass"} to="/game/">
            <button className="returngame pointer center" ><img alt='' className="imglink arrowreturn" src={Arrow}></img>Retour au jeux</button>
        </NavLink>
      </div>
    )
  );
}
import React, {useRef,useContext} from "react";
import { useEffect} from "react";
import {Context} from '../../context/ContextProvider';
import './Header.css';
//import { ReactComponent as Close } from './../../items/Image/close.svg';
import { ReactComponent as Check } from './../../items/Image/check.svg';
import $ from "jquery";
//import { login, register , checkco , deco , getUserInfo } from '../../actions/user.js';
import { login, register } from '../../actions/user.js';
import PasswordField from '../user/PasswordField';



export default function Boxco2(props) {
  const {connected, updateconnected} = useContext(Context);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);


  const successco =(check)=>{
    if(check){
        $(".titleboxco").addClass("disparition");
        $(".formboxco").addClass("disparition");
        $(".textboxco").addClass("disparition");
        $(".btnboxco2").addClass("disparition");
        $(".boxco").addClass("transi3")
        setTimeout(function(){
            props.setcoSuccess(true);
            $(".titleboxco").removeClass("disparition");
            $(".formboxco").removeClass("disparition");
            $(".textboxco").removeClass("disparition");
            $(".btnboxco2").removeClass("disparition");
            $(".boxco").removeClass("transi3")
        },400);
    }
  }
  const successreg =(check)=>{
    if(check){
        $(".titleboxco").addClass("disparition");
        $(".formboxco").addClass("disparition");
        $(".textboxco").addClass("disparition");
        $(".btnboxco2").addClass("disparition");
        $(".boxco").addClass("transi3")
        setTimeout(function(){
            props.setregSuccess(true);
            $(".titleboxco").removeClass("disparition");
            $(".formboxco").removeClass("disparition");
            $(".textboxco").removeClass("disparition");
            $(".btnboxco2").removeClass("disparition");
            $(".boxco").removeClass("transi3")
        },400);


    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = emailRef.current.value? emailRef.current.value : null;
    const password = passwordRef.current.value ? passwordRef.current.value : null;

    if (!props.regist) return login({ email, password } , updateconnected , successco , props.changemessageError );
    const name = nameRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;
    if (password === confirmPassword) return register({ name, email, password } , updateconnected , successreg , props.changemessageError );
    if(confirmPassword!==password){
      props.seterrorMessage("Les mots de passes ne correspondent pas");
    }
    
  };
  const ChangeRegi = (e) => {
    e.preventDefault();
    props.changeregister(!props.regist);
    props.seterrorMessage(false);
    props.setcoSuccess(false);
    props.setregSuccess(false);
  };

  /*
  const handleSubmitcheck = (e) => {
    e.preventDefault();
    checkco(updateconnected);
  };
  const handleDeco = (e) => {
    e.preventDefault();
    deco(updateconnected);
  };
  const handleUserInfo = (e) => {
    e.preventDefault();
    getUserInfo(updateconnected);
  };
*/

  useEffect(
    () => {

      $( ".closesvg" ).mouseover(function() {
        $(".cls-3").attr("class","cls-3 hamenter")
      });
      $( ".closesvg" ).mouseleave(function() {
        $(".cls-3").attr("class","cls-3")
      });


   
      return () => {
      }

    },
    [],
  );

  useEffect(
    () => {


   
      return () => {
      }

    },
    [connected],
  );


  return (
    <>
        <div className="boxco02 pastouche scrollbar scrollbary">

            {!props.coSuccess&&!props.regSuccess&&(
            <>
                <h3 style={{marginTop:"2em"}} className="pastouche titleboxco">
                    {props.regist ? 'Inscription' : 'Connexion'}
                </h3>
                <form className="formboxco pastouche" onSubmit={handleSubmit}>
                    <>
                    {props.errorMessage&&(<p className="errorm pastouche">{props.errorMessage}</p>)}

                    {props.regist && (
                        <input
                        autoFocus
                        margin="normal"
                        variant="standard"
                        id="name"
                        label="Name"
                        type="text"
                        className="pastouche inp inpin"
                        placeholder="Nom"
                        ref={nameRef}
                        props={{ minLength: 2 }}
                        required
                        />
                    )}
                    <input
                        autoFocus={!props.regist}
                        margin="normal"
                        variant="standard"
                        id="email3"
                        className="pastouche inp inpin"
                        placeholder="Email"
                        label="Email"
                        type="email"
                        
                        ref={emailRef}
                        required
                    />
                    <PasswordField {...{ passwordRef }}               
                        ref={passwordRef}
                        /*id="passwordRef"*/
                        label="passwordRef"
                        placeholder="Mot de passe" 
                        />
                    {props.regist && (
                        <PasswordField
                        ref={confirmPasswordRef}
                        id="confirmPassword"
                        label="Confirm Password"
                        placeholder="Confirmez votre mot de passe"
                        />
                    )}
                    </>
                    <>
                    <button id="submitboxco3" type="submit" variant="contained" className="pastouche btn btnboxco1 pointer" >
                        Envoyer
                    </button>
                    </>
                </form>
                <>  
                    <p className="textcenter textboxco pastouche">
                    {props.regist
                    ? 'Vous avez déjà un compte ? Connectez vous '
                    : "Vous n'avez pas encore de compte? Créez en un "}</p>
                    <button className="pastouche btn btnboxco2 pointer" onClick={ChangeRegi}>
                    {props.regist ? 'Se Connecter' : 'Créer un compte'}
                    </button>
                </>
            </>
            )}
            {props.coSuccess&&(
                <>
                <div className="boxsuccess pastouche">
                    <div className="roundCheck apparition pastouche">
                        <Check className="bandg checkbox pastouche"></Check>
                    </div>
                    <p className="textcenter pastouche">Vous êtes bien connecté</p>
                </div>
                </>
            )}
            {props.regSuccess&&(
                <>
                <div className="boxsuccess pastouche">
                    <div className="roundCheck apparition pastouche">
                        <Check className="bandg checkbox pastouche"></Check>
                    </div>
                    <p className="textcenter pastouche">Vous vous êtes bien inscrit</p>
                </div>
                </>
            )}
        </div>

    </>
  );
}
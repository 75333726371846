import React, {useState ,useContext ,useCallback} from "react";
import { useEffect} from "react";
import {Context} from '../context/ContextProvider';
import { checkcon } from './../actions/user.js';

import './Page.css';
import { ReactComponent as Arrow } from './../items/Image/arrow.svg';
import {useNavigate, NavLink} from "react-router-dom";
import Donate from "./../items/Image/donate.svg"
import Pub from "../Components/Pub/Pub";
import PubHorizontal from "../Components/Pub/PubHorizontal";


import Logo from "./../items/Image/Bookplus.svg"
import { useDispatch } from "react-redux";
import { setonHeader } from "../features/onHeader.js";
import { setonFooter } from "../features/onFooter.js";
import { useSelector } from 'react-redux';




export default function Game(props) {
  //const {connected, updateconnected} = useContext(Context);
  const {connected} = useContext(Context);

  const navigate = useNavigate();
  const [onGame, setOnGame] = useState(true);
  const onHeader=useSelector(state => state.onHeader);
  //const onFooter=useSelector(state => state.onFooter);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setonHeader(false));
    dispatch(setonFooter(false));
    return () => {
      dispatch(setonHeader(true));
      dispatch(setonFooter(true));
    }
  },[dispatch]);

  const bringMenu = (e) => {
    setOnGame(!onGame);
    /*
    $(".head").addClass("exit");
    setTimeout(function(){
        $(".head").removeClass("exit");
    },100)
    */
    /*
    const headerElement = document.querySelector('.head');
  
    // Ajouter la classe 'exit'
    headerElement.classList.add('exit');
    
    // Utiliser setTimeout pour retirer la classe 'exit' après 100 ms
    setTimeout(function() {
      headerElement.classList.remove('exit');
    }, 100);
    */
    dispatch(setonHeader(!onHeader.value));
  };

const goConnexion = useCallback((bool) => {
    if (!bool) {
        navigate("/connexion", { connection: true });
    }
}, [navigate]); 

useEffect(
    () => {

        if(!connected){
            checkcon(goConnexion);
            //navigate("/connexion", { connection: true });
        }
        else if(connected){
        }

      return () => {
      }

    },
    [connected,goConnexion],
);

                
useEffect(() => {
  const intervalID = setInterval(() => {
    checkcon(goConnexion);
  }, 80000);  // 300000 millisecondes équivalent à 1 heure

  return () => {
    clearInterval(intervalID);  // Supprimer l'intervalle lorsque le composant est démonté
  };
}, [goConnexion]);

/*
useEffect(
    () => {


      //$(".footer").addClass("exit");

   
      //return () => {
        //$(".footer").removeClass("exit");
      //}

        // Sélectionner l'élément avec la classe 'footer'
      //const footerElement = document.querySelector('.footer');
  
       // Ajouter la classe 'exit'
      //footerElement.classList.add('exit');

      // Retourner une fonction de nettoyage qui supprimera la classe 'exit'
      //return () => {
        //footerElement.classList.remove('exit');
      //};
      

    },
    [],
);
*/


  return (
    <>
      {connected&&(
      <section id="game" className={onGame ? "biggame apparition" : "bigboxg apparition"}>
        <div className="fondsalle apparition">
            {/*<img className="imgfond" src={imagelist[salle][0]}></img>*/}
        </div>
        <div className="gamefrange1 apparition">
        {onGame&&(
            <Arrow onClick={bringMenu} className="arrow pointer tournappear"></Arrow>
        )}
        {!onGame&&(
            <Arrow onClick={bringMenu} className="arrow2 pointer tournappear2"></Arrow>
        )}
        </div>
        {/*
        <div className="gamefrange apparition">
        </div>
        <div className="gamefrange">
        </div>
        */}
        <div className="enpar3">
          <div className="par1 part">
            <Pub pubNumber={1} Type={""} Exist={false} />
          </div>

          <div className='centercol apparitionq par0 centerco'>
                  <div id="logo" className="cadrelogo2 centerco">
                  <div className="boxlogo centerco apparitionq">        
                      <img alt='Logo' src={Logo} className="imglogo"></img>
                      <h1 className="titrelogo">HÂfIDH</h1>
                  </div>
                  <h3 className="sublogo apparitionq">Allez vous le compléter ?</h3>
                  </div>
                  {props.children}
                  <div id="theposition" style={{height:"4em"}}></div>
                  <hr style={{width:"20em",maxWidth:"98vw"}}/>
                  <div id="help" className="helpbox centerco">
                    <div className="help centerco apparitionq"> 
                      <p className="talign">Vous pouvez nous soutenir pour poursuivre et perfectionner ce projet, ainsi que pour en initier de nouveaux.</p>
                      <NavLink className={"linkclass apparitionq"} target="_blank" rel="noopener noreferrer" to="https://fr.tipeee.com/ummahtech/">
                          <button  className="btn0 center">Donation <div style={{width:"0.5em"}}></div><img alt='bouton donation' className="imglink" src={Donate}></img></button>
                      </NavLink>

                    </div>
                  </div>
                  <div  className="helpbox centerco">
                    <div className="pubh centerco apparitionq"> 
                        <PubHorizontal pubNumber={3} Type={""} Exist={false}/>
                        <PubHorizontal pubNumber={4} Type={""} Exist={false}/>
                    </div>
                  </div>
                  <div style={{height:"3em"}}></div>
          </div>
          <div className="par2 part">
            <Pub pubNumber={2} Type={""} Exist={false}  />
          </div>
        </div>

      </section>
      )}
    </>
  );
}
import React, {useContext , useEffect , useState} from "react";
import {Context} from '../context/ContextProvider';
import './Page.css';
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "./../Components/ToggleSwitch/ToggleSwitch";
//import Arrow from './../items/Image/arrow3.svg';
import $ from "jquery";
import { ChangeArabicLecture , ChangeEnglishLecture , ChangeFrenchLecture , getReglage } from "../actions/user.js";




export default function Classement() {
  //const {connected, updateconnected} = useContext(Context);
  const {connected} = useContext(Context);
  const navigate = useNavigate();
  const [suratInfo, setSuratInfo] = useState({});

  const [French, setFrench] = useState(false);
  const [English, setEnglish] = useState(false);
  const [Arabic, setArabic] = useState(false);
  const [Begin, setBegin] = useState(false);
  const [selected, setSelected] = useState("nonedebut");

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  useEffect(
    () => {
  
      getReglage((result)=>{
        setArabic(result.ArabicLecture);
        setFrench(result.FrenchLecture);
        setEnglish(result.EnglishLecture);
        setBegin(true);
  
      })
  
      return () => {
      }
  
    },
    [connected],
  );



  const getSuratInfoApi=()=>{
    //const data = { UserId: 1,Game:id};


    fetch('https://hafiz-quran.com/express/suratinfo', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
    .then((response) => response.json())
    .then((data) => {
      setSuratInfo(data);
    },

      (error) => {
        console.log("Pas de Connexion");
      }

    );
  }
    useEffect(() => {

        getSuratInfoApi();

        return () => {
        }
    }, []);

    let datal=""
    if(suratInfo[1]){
        for(let i=0; i<Object.keys(suratInfo).length; i++){
            datal=datal+"<option value="+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" >"+suratInfo[Object.keys(suratInfo)[i]]["idCanonique"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Francais"]+" "+suratInfo[Object.keys(suratInfo)[i]]["Arabe"]+"</option>";
        }
    }

    const changeInputVerse1=()=>{
      if(Math.floor($("#theverse1").val())&& Math.floor($("#theverse1").val())>=1 && Math.floor($("#theverse1").val())<=114){
        $("#nbverseV1").html(suratInfo[Math.floor($("#theverse1").val())-1]["Nbreverset"]);
        $("#verseV1").attr("max",suratInfo[Math.floor($("#theverse1").val())-1]["Nbreverset"]);
      }
      else{
        $("#nbverseV1").html(0);
        $("#verseV1").attr("max",1);
      }
    }
    
    const changeInputVerse2=()=>{
      if(Math.floor($("#theverse2").val())&& Math.floor($("#theverse2").val())>=1 && Math.floor($("#theverse2").val())<=114){
        $("#nbverseV2").html(suratInfo[Math.floor($("#theverse2").val())-1]["Nbreverset"]);
        $("#verseV2").attr("max",suratInfo[Math.floor($("#theverse2").val())-1]["Nbreverset"]);
      }
      else{
        $("#nbverseV2").html(0);
        $("#verseV2").attr("max",1);
      }
    }
    const changeInputVerse=()=>{
      if(Math.floor($("#theverse").val())&& Math.floor($("#theverse").val())>=1 && Math.floor($("#theverse").val())<=114){
        $("#nbverseV").html(suratInfo[Math.floor($("#theverse").val())-1]["Nbreverset"]);
        $("#verseV").attr("max",suratInfo[Math.floor($("#theverse").val())-1]["Nbreverset"]);
      }
      else{
        $("#nbverseV").html(0);
        $("#verseV").attr("max",1);
      }
    }
    const changeInputSurat=()=>{
      if(Math.floor($("#thesurat").val())&& Math.floor($("#thesurat").val())>=1 && Math.floor($("#thesurat").val())<=114){
        $("#nbverseS").html(suratInfo[Math.floor($("#thesurat").val())-1]["Nbreverset"]);
        $("#verseS").attr("max",suratInfo[Math.floor($("#thesurat").val())-1]["Nbreverset"]);
      }
      else{
        $("#nbverseS").html(0);
        $("#verseS").attr("max",1);
      }
    }
    const changeInputRub=()=>{
      let valeur = $("#rub").val();
      if($("#choice").val()==="Rub" && Number.isInteger(+valeur) && +valeur >= 1 && +valeur <= 240){
      }
    }
    const changeInputHizb=()=>{
      let valeur = $("#hizb").val();
      if($("#choice").val()==="Hizb" && Number.isInteger(+valeur) && +valeur >= 1 && +valeur <= 60){
      }
    }
    const changeInputJuz=()=>{
      let valeur = $("#juz").val();
      if($("#choice").val()==="Juz" && Number.isInteger(+valeur) && +valeur >= 1 && +valeur <= 30){
      }
    }
    
    const changeChoice=()=>{
        if($("#choice").val()==="Verset"){
            $("#txtinput").addClass("exit");
            $("#juz").addClass("exit");
            $("#hizb").addClass("exit");
            $("#rub").addClass("exit");
            $("#juztxtinput").addClass("exit");
            $("#hizbtxtinput").addClass("exit");
            $("#rubtxtinput").addClass("exit");
            $("#suratbox").addClass("exit");
            $("#versebox").removeClass("exit");
            $("#bversebox").addClass("exit");


        }
        else if($("#choice").val()==="Surat"){
          $("#txtinput").addClass("exit");
          $("#juz").addClass("exit");
          $("#hizb").addClass("exit");
          $("#rub").addClass("exit");
          $("#juztxtinput").addClass("exit");
          $("#hizbtxtinput").addClass("exit");
          $("#rubtxtinput").addClass("exit");
          $("#suratbox").removeClass("exit");
          $("#versebox").addClass("exit");
          $("#bversebox").addClass("exit");
        }
        else if($("#choice").val()==="BVerse"){
          $("#txtinput").addClass("exit");
          $("#juz").addClass("exit");
          $("#hizb").addClass("exit");
          $("#rub").addClass("exit");
          $("#juztxtinput").addClass("exit");
          $("#hizbtxtinput").addClass("exit");
          $("#rubtxtinput").addClass("exit");
          $("#suratbox").addClass("exit");
          $("#versebox").addClass("exit");
          $("#bversebox").removeClass("exit");
        }
        else if($("#choice").val()==="Juz"){
            $("#txtinput").removeClass("exit");
            $("#juz").removeClass("exit");
            $("#hizb").addClass("exit");
            $("#rub").addClass("exit");
            $("#juztxtinput").removeClass("exit");
            $("#hizbtxtinput").addClass("exit");
            $("#rubtxtinput").addClass("exit");
            $("#suratbox").addClass("exit");
            $("#versebox").addClass("exit");
            $("#bversebox").addClass("exit");

        }
        else if($("#choice").val()==="Hizb"){
            $("#txtinput").removeClass("exit");
            $("#juz").addClass("exit");
            $("#hizb").removeClass("exit");
            $("#rub").addClass("exit");
            $("#juztxtinput").addClass("exit");
            $("#hizbtxtinput").removeClass("exit");
            $("#rubtxtinput").addClass("exit");
            $("#suratbox").addClass("exit");
            $("#versebox").addClass("exit");
            $("#bversebox").addClass("exit");

        }
        else if($("#choice").val()==="Rub"){
            $("#txtinput").removeClass("exit");
            $("#juz").addClass("exit");
            $("#hizb").addClass("exit");
            $("#rub").removeClass("exit");
            $("#juztxtinput").addClass("exit");
            $("#hizbtxtinput").addClass("exit");
            $("#rubtxtinput").removeClass("exit");
            $("#suratbox").addClass("exit");
            $("#versebox").addClass("exit");
            $("#bversebox").addClass("exit");

        }
        else{
            $("#txtinput").addClass("exit");
            $("#juz").addClass("exit");
            $("#hizb").addClass("exit");
            $("#rub").addClass("exit");
            $("#juztxtinput").addClass("exit");
            $("#hizbtxtinput").addClass("exit");
            $("#rubtxtinput").addClass("exit");
            $("#suratbox").addClass("exit");
            $("#versebox").addClass("exit");
            $("#bversebox").addClass("exit");


        }
        
    }       

    const GoLecture = ()=>{

      if($("#choice").val()==="Verset"){
        let valuesurat=Math.floor($("#theverse").val());
        let valueverse=Math.floor($("#verseV").val());
        let nubverse=suratInfo[Math.floor($("#theverse").val())-1]["Nbreverset"];
        if(valuesurat && valueverse && valuesurat<=114 && valuesurat>=1 && valueverse<=nubverse && valueverse>=1){
            if(selected==="nonedebut"){
              navigate('/verse/'+(valuesurat).toString()+"/"+(valueverse).toString(), {})
            }
            else if(selected==="basmalahdebut"){
              navigate('/basmalah/'+(valuesurat).toString()+"/"+(valueverse).toString(), {})
            }
            else if(selected==="istidebut"){
              navigate('/istiadha/'+(valuesurat).toString()+"/"+(valueverse).toString(), {})
            }
        }


      }
      else if($("#choice").val()==="Surat"){

        let valuesurat=Math.floor($("#thesurat").val());
        let valueverse=Math.floor($("#verseS").val());
        let nubverse=suratInfo[Math.floor($("#thesurat").val())-1]["Nbreverset"];
        if(valuesurat && valueverse && valuesurat<=114 && valuesurat>=1 && valueverse<=nubverse && valueverse>=1){
            navigate('/surat/'+(valuesurat).toString()+"/"+(valueverse).toString(), {})
        }
        else if(valuesurat && valuesurat<=114 && valuesurat>=1 ){
          navigate('/surat/'+(valuesurat).toString()+"/0", {})
        }

      }
      else if($("#choice").val()==="BVerse"){

        if($("#choice").val()==="Verset"){
          let valuesurat1=Math.floor($("#theverse1").val());
          let valueverse1=Math.floor($("#verseV1").val());
          let valuesurat2=Math.floor($("#theverse2").val());
          let valueverse2=Math.floor($("#verseV2").val());
          let nubverse1=suratInfo[Math.floor($("#theverse1").val())-1]["Nbreverset"];
          let nubverse2=suratInfo[Math.floor($("#theverse2").val())-1]["Nbreverset"];

          if(valuesurat1 && valueverse1 && valuesurat2 && valueverse2 && valuesurat1<=114 && valuesurat1>=1 && valueverse1<=nubverse1 && valueverse1>=1 && valuesurat2<=114 && valuesurat2>=1 && valueverse2<=nubverse2 && valueverse2>=1){
              navigate('/bverse/'+(valuesurat1).toString()+"/"+(valueverse1).toString()+'/'+(valuesurat2).toString()+"/"+(valueverse2).toString(), {})
          }
        }
      }
      else if($("#choice").val()==="Juz"){
        let valeur = Math.floor($("#juz").val());
        if(valeur && +valeur >= 1 && +valeur <= 30){
          navigate('/juz/'+(valeur).toString()+"/0", {})
        }

      }
      else if($("#choice").val()==="Hizb"){
        let valeur = Math.floor($("#hizb").val());
        if(valeur && +valeur >= 1 && +valeur <= 60){
          navigate('/hizb/'+(valeur).toString()+"/0", {})
        }

      }
      else if($("#choice").val()==="Rub"){
        let valeur = Math.floor($("#rub").val());
        if(valeur && +valeur >= 1 && +valeur <= 240){
          navigate('/rub/'+(valeur).toString()+"/0", {})
        }

      }
      else{

      }

    }
  






  return (
    <>
    <div className="centerco apparitionq">
    <h1 className="talign">Lecture</h1>
    <p className="centerco">
            <label className="talign" htmlFor="choice">Lecture par :</label><br/>
            <select name="choice" className="inp4 inputdefault pointer inpaddgame choiceleader" id="choice"  onInput={changeChoice}>
                <option value="Verset">Verset</option>
                <option value="Surat">Surat</option>
                <option className="exit" value="Rub">Rub</option>
                <option className="exit" value="Hizb">Hizb</option>
                <option className="exit" value="Juz">Juz</option>
                <option className="exit" value="BVerse">Entre 2 Verset</option>
            </select>
    </p>
    <p id="txtinput" style={{marginTop:"3em"}} className="apparitionq exit">Sur lequel d'entre eux ? maximum <span id="juztxtinput" className="exit">30</span><span id="hizbtxtinput" className="exit">60</span><span id="rubtxtinput" className="exit">240</span></p>
        <input type="number" name="juz" id="juz" className="inp3 inputdefault apparitionq pointer exit inpaddgame" min={1} max={30} placeholder="Juz" required="" onInput={changeInputJuz}></input>
        <input type="number" name="hizb" id="hizb" className="inp3 inputdefault apparitionq pointer exit inpaddgame" min={1} max={60} placeholder="Hizb" required="" onInput={changeInputHizb}></input>
        <input type="number" name="rub" id="rub" className="inp3 inputdefault apparitionq pointer exit inpaddgame" min={1} max={240} placeholder="Rub" required="" onInput={changeInputRub}></input>
        
        <div id="suratbox" className=" centerco exit" style={{maxWidth:"90vw"}}>
          <div style={{marginTop:"3em"}}></div>
          <p id="surattxtinput" className="apparitionq">Sur quel sourate ? maximum 114</p>
          <input id="thesurat" className="inp1 inputdefault apparitionq pointer talign  surantinpaddgame" name="thesurat" placeholder="Surat" list="suratname" onInput={changeInputSurat}></input>
            <datalist id="suratname">

                <div dangerouslySetInnerHTML={{ __html: datal }} />

            </datalist>
          <div style={{marginTop:"1em"}}></div>
          <div style={{textAlign:"center"}}>{"(Facultatif) Voulez vous commencer sur un verset en particulier ?"}</div>
          <p>{"Nombre de versets dans la sourate choisis : "}<span id="nbverseS">0</span></p>
          <input type="number" name="verseS" id="verseS" className="inp3 inputdefault" min={1} max={1} placeholder="Verse" required=""></input>
        </div>

        <div id="versebox" className=" centerco">
          <div style={{marginTop:"3em"}}></div>
          <p className="apparitionq">Sur quel sourate ? maximum 114</p>
          <input id="theverse" className="inp1 inputdefault apparitionq pointer talign  surantinpaddgame" name="theverse" placeholder="Surat" list="suratname" onInput={changeInputVerse}></input>
            <datalist id="suratname">

                <div dangerouslySetInnerHTML={{ __html: datal }} />

            </datalist>
          <p>{"Nombre de versets dans la sourate choisis : "}<span id="nbverseV">0</span></p>
          <input type="number" name="verseV" id="verseV" className="inp3 inputdefault" min={1} max={1} placeholder="Verse" required=""></input>
          <div className="centercostart formbasm">
            <p>{"Voulez vous commencer par la Basmalah ou l'Isti'adha ?"}</p>
              <label htmlFor="nonedebut"  className="pointer" ><input type="radio" name="debut" id="nonedebut" className="pointer"  value={"nonedebut"} checked={selected === 'nonedebut'} onChange={handleChange} ></input>Non</label>
              <label htmlFor="basmalahdebut"  className="pointer" ><input type="radio" name="debut" id="basmalahdebut"  className="pointer"  value={"basmalahdebut"} checked={selected === 'basmalahdebut'} onChange={handleChange} ></input>Basmalah</label>
              <label htmlFor="istidebut"  className="pointer"><input type="radio" name="debut" id="istidebut"  className="pointer" value={"istidebut"} checked={selected === 'istidebut'} onChange={handleChange} ></input>Isti'adha</label>
          </div>
        </div>

        <div id="bversebox" className="exit centerco">
          <div style={{marginTop:"3em"}}></div>
          <p  className="apparitionq">{"Sourate du premier verset ( maximum 114 )"} </p>
          <input id="theverse1" className="inp1 inputdefault apparitionq pointer talign  surantinpaddgame" name="theverse1" placeholder="Surat" list="suratname" onInput={changeInputVerse1}></input>
            <datalist id="suratname">

                <div dangerouslySetInnerHTML={{ __html: datal }} />

            </datalist>
            <p>{"Nombre de versets dans la sourate choisis : "}<span id="nbverseV1">0</span></p>
            <input type="number" name="verseV1" id="verseV1" className="inp3 inputdefault" min={1} max={1} placeholder="Verse" required=""></input>
            <div style={{marginTop:"2em"}}></div>
            <p  className="apparitionq">{"Sourate du deuxieme verset ( maximum 114 )"} </p>
          <input id="theverse2" className="inp1 inputdefault apparitionq pointer talign  surantinpaddgame" name="theverse2" placeholder="Surat" list="suratname" onInput={changeInputVerse2}></input>
            <datalist id="suratname">

                <div dangerouslySetInnerHTML={{ __html: datal }} />

            </datalist>
            <p>{"Nombre de versets dans la sourate choisis : "}<span id="nbverseV2">0</span></p>
            <input type="number" name="verseV2" id="verseV2" className="inp3 inputdefault" min={1} max={1} placeholder="Verse" required=""></input>
        </div>

      <div style={{height:"1.5em"}}></div>
      <button id="btnsend" className="btn btn3" onClick={GoLecture}>Lancer la Lecture</button>
      <div style={{height:"3em"}}></div>

    </div>


    {connected && Begin && (
      <div className="centerco apparitionq">
        <div style={{height:"1em"}}></div>
        <div className="center">Lecture en Arabe : <ToggleSwitch first={Arabic} fonct={(result)=>{ChangeArabicLecture(result)}}></ToggleSwitch></div>
        <div className="center">Lecture en Français : <ToggleSwitch first={French} fonct={(result)=>{ChangeFrenchLecture(result)}}></ToggleSwitch></div>
        <div className="center">Lecture en Anglais : <ToggleSwitch first={English} fonct={(result)=>{ChangeEnglishLecture(result)}}></ToggleSwitch></div>
        <div style={{height:"1em"}}></div>
      </div>
    )}

    </>
  );
}

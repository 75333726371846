import React, {useContext,useCallback} from "react";
import {Context} from './../../context/ContextProvider';
import './../Page.css';
import './Game.css';
import {useNavigate ,NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteAgame, getListGame , checkcon , ChangeHideGame} from "./../../actions/user.js";
import Controller from "./../../items/Image/controller.svg";
import Arrow from './../../items/Image/arrow3.svg';
import ToggleSwitch from "./../../Components/ToggleSwitch/ToggleSwitch";






function groupBy(list, groupSize) {
    const groups = [];
    for (let i = 0; i < list.length; i += groupSize) {
      groups.push(list.slice(i, i + groupSize));
    }
    return groups;
}


export default function ListGame() {
  //const {connected, updateconnected} = useContext(Context);
  const {connected} = useContext(Context);

  const navigate = useNavigate();

  const [ListGame, setListGame] = useState([]);
  const [numfrange, setnumfrange] = useState(0);
  const [maxfrange, setmaxfrange] = useState(0);
  const [frange, setfrange] = useState([]);

  const PreviousFrange =()=>{

    if(numfrange>0){
      setfrange( ListGame.slice((numfrange-1)*8, (numfrange-1)*8+8));
      setnumfrange(numfrange-1);
    }
  }
  const NextFrange =()=>{
    if(numfrange<maxfrange && maxfrange>0 && numfrange>=0){
      setfrange( ListGame.slice((numfrange+1)*8, (numfrange+1)*8+8));
      setnumfrange(numfrange+1);
    }
  }
  useEffect(()=>{
    setnumfrange(0);
    const numberOfElements = ListGame.length;
    setmaxfrange(Math.ceil(numberOfElements / 8)-1);
    setfrange( ListGame.slice(0, 8));

  },[ListGame])


  const goConnexion = useCallback((bool) => {
    if (!bool) {
        navigate("/connexion", { connection: true });
    }
}, [navigate]); 


  useEffect(
    () => {

        if(!connected){
            checkcon(goConnexion);
            //navigate("/connexion", { connection: true });
        }
        else if(connected){
        }

      return () => {
      }

    },
    [connected,goConnexion],
);


    useEffect(() => {

        getListGame((result)=>{
            setListGame(result);
        })
        return () => {
        }


    }, []);

    const deleteThisGame=(IDGame)=>{
        deleteAgame(IDGame,()=>{
            getListGame((result)=>{
                setListGame(result);
            })
        })
    }

    const getFullName=(Version)=>{
        if(Version && Version==="Classique"){
            return "Classique"
        }
        if(Version && Version==="SuratO"){
            return "Surat Only"
        }
    }

    const groupedByTwo = groupBy(frange, 2);
    const groupedByFour = groupBy(groupedByTwo, 2);

    return (
        <>
            <div className="centerco" style={{marginTop:"2em"}}>
                {groupedByFour.map((groupOfFour, indexGroup) => (
                    <div className="boxgroup4" key={indexGroup} >
                    {groupOfFour.map((groupOfTwo, indexTwo) => (
                        <div className="boxgroup2" key={indexTwo} >
                        {groupOfTwo.map((game, indexGame) => (
                            <div className="boxl apparitionq" key={indexGame} >
                                <div className="infolist">
                                    <p className="talign">Id : {game.IDGame}</p>
                                    <p className="talign">{getFullName(game.Version)}</p>
                                    <p className="talign">Type : {game.Type}</p>
                                    {(game.Type!=="All")&&(<p className="talign" >{game.Type} : {game.Typenumber}</p>)}
                                </div>
                                <div className="scorelist">
                                    <p className="talign">Score : {game.Score}</p>
                                    <p className="talign">BestScore : {game.BestScore}</p>
                                </div>
                                <div className="boxlist">
                                    <div className="infolist exit">
                                    <p>Id : {game.IDGame}</p>
                                    <p>Type : {game.Type}</p>
                                    {(game.Type!=="All")&&(<p>{game.Type} : {game.Typenumber}</p>)}
                                    </div>
                                    <div className="centerco">
                                        <NavLink className={"linkclass"} to={"/game/play/"+game.IDGame}>
                                            <button className="returngame pointer center" ><img alt='' className="imglink " src={Controller}></img>Jouer</button>
                                        </NavLink>
                                            <button className="returngame2 pointer center"  onClick={()=>{deleteThisGame(game.IDGame)}} >Supprimer</button>
                                        {!game.Perso && ( 
                                            <div className="center">Hide : <ToggleSwitch first={game.Hide} fonct={(result)=>{ChangeHideGame(game.IDGame,result)}}></ToggleSwitch></div>
                                        )}
                                        {game.Perso && ( 
                                            <>
                                                <div className="center">Partie Personnalisé</div>
                                                <div className="center">Timer :  {game.Timer} </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                            </div>
                        ))}
                        </div>
                    ))}
                    </div>
                ))}
                <div className="center">
                    <button className={numfrange===0 ? "bt0 returngame pointer center" : " returngame pointer center"} /*className="returngame pointer center"*/ onClick={PreviousFrange} ><img alt='jeux précédents' className="smallarrow" src={Arrow}></img></button>
                    <button className={(numfrange===maxfrange || maxfrange<=0 ) ? "bt0 returngame pointer center" : " returngame pointer center"} onClick={NextFrange} ><img alt='jeux suivants' className="smallarrow2" src={Arrow}></img></button>
                </div>
                <div style={{height:"2em"}}></div>
            </div>
            <div className="centerco"> 
                <NavLink className={"linkclass"} to="/game/">
                    <button className="returngame pointer center" ><img alt='' className="imglink arrowreturn" src={Arrow}></img>Retour au menu</button>
                </NavLink>
                <NavLink className={"linkclass"} to="/game/new-game">
                    <button className="returngame pointer center" >Nouvelle partie<img alt='' className="imglink arrowreturn2" src={Arrow}></img></button>
                </NavLink>
            </div>
        </>
      );
}